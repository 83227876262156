import React from 'react'
import PropTypes from 'prop-types'
import {Alert as ChakraAlert} from '@salesforce/retail-react-app/app/components/shared/ui'
import {AlertIcon, AlertTitle, AlertDescription, useToken} from '@chakra-ui/react'
import {Alert2Icon as CustomAlertIcon, SuccessIcon} from '../../../custom-icons'
import {VStack, useStyleConfig} from '@salesforce/retail-react-app/app/components/shared/ui'

/**
 * Alert component for displaying important messages to the user.
 *
 * This component is a customizable alert box that can display a title, description,
 * and optional custom icons.
 * svgIconTitle, svgIconDescription are important for contexts without intl like Toast
 *
 * @param {Object} props - The properties object.
 * @param {string} props.status - The status of the alert, which determines the styling and icon used.
 * @param {string} props.variant - The variant of the alert, which can override the default styling based on status.
 * @param {string} props.title - The title text displayed at the top of the alert.
 * @param {string} props.description - The description text providing more details about the alert.
 * @param {React.ReactNode} props.children - Additional content or elements to be displayed within the alert.
 * @param {string} props.svgIconTitle - The title attribute for the SVG icon, used for accessibility.
 * @param {string} props.svgIconDescription - The description attribute for the SVG icon, used for accessibility.
 * @param {boolean} [props.customIcon=true] - Flag to determine whether to use a custom icon or the default alert icon.
 *
 * @returns {JSX.Element} The rendered alert component.
 */
const Alert = ({
    status,
    variant,
    title,
    description,
    children,
    svgIconTitle,
    svgIconDescription,
    customIcon = true
}) => {
    const alertStyles = useStyleConfig('Alert', {variant: status})
    const [alertIconInsideColor] = useToken('colors', [alertStyles?.icon?.insideColor])

    const CustomIcon =
        status === 'success' ? (
            <SuccessIcon
                {...alertStyles?.icon}
                svgTitle={svgIconTitle}
                svgDescription={svgIconDescription}
            />
        ) : (
            <CustomAlertIcon
                {...alertStyles?.icon}
                insideColor={alertIconInsideColor}
                svgTitle={svgIconTitle}
                svgDescription={svgIconDescription}
            />
        )

    return (
        <ChakraAlert status={status} variant={variant || status}>
            {customIcon ? CustomIcon : <AlertIcon />}
            <VStack spacing={2} align="start">
                {title && <AlertTitle>{title}</AlertTitle>}
                <AlertDescription>{description}</AlertDescription>
            </VStack>
            {children}
        </ChakraAlert>
    )
}

Alert.propTypes = {
    status: PropTypes.oneOf(['info', 'success', 'warning', 'error']).isRequired,
    variant: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    children: PropTypes.node,
    svgIconTitle: PropTypes.string,
    svgIconDescription: PropTypes.string,
    customIcon: PropTypes.bool
}

export default Alert
