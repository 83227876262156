import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {Flex, Text, Box, useBreakpointValue} from '@chakra-ui/react'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {DYNAMIC_IMAGE_WIDTHS} from '../../../constants.js'

/**
 * @param {string} title - title of the component
 * @param {string} titleHeadingLevel - font size of the title
 * @param {string} titleFontColor - font color of the title
 * @param {string} subTitle - subtitle of the component
 * @param {string} textColor - text color
 * @param {object} smallImage - small image
 * @param {object} mediumImage - medium image
 * @param {object} largeImage - large image
 * @param {object} xlargeImage - xlarge image
 * @param {object} xxlargeImage - xxlarge image
 * @param {string} imageTitle - image title
 * @param {string} imageAlt - image alt
 * @param {object} regions - regions
 * @returns {JSX.Element} - PDCTitlePageOnHeaderImage component
 */
export const PDCTitlePageOnHeaderImage = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    textColor,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt
}) => {
    const titleId = useId()

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    return (
        <Box aria-describedby={titleId} as="section" position="relative" width={'100%'} py={'41px'}>
            <DynamicImage
                src={`${currentImage?.url}[?sw={width}]`}
                widths={DYNAMIC_IMAGE_WIDTHS}
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: imageAlt === 'empty' ? '' : imageAlt || title,
                    title: imageTitle === 'empty' ? '' : imageTitle || title
                }}
            />
            <Flex
                direction={'column'}
                justifyContent={'center'}
                alignItems={'left'}
                top="0"
                left="0"
                width="100%"
                height="100%"
                position={'relative'}
                px={['10px', null, '40px']}
            >
                <PDCTitle
                    title={title}
                    titleFontColor={titleFontColor || textColor || 'white'}
                    titleHeadingLevel={titleHeadingLevel}
                    id={titleId}
                    size="5xl"
                />
                <Text
                    dangerouslySetInnerHTML={{
                        __html: subTitle
                    }}
                    variant="eyeBrowLarge"
                    color={textColor || 'white'}
                    mt="16px"
                />
            </Flex>
        </Box>
    )
}

PDCTitlePageOnHeaderImage.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    textColor: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
