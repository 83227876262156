import React from 'react'
import PropTypes from 'prop-types'

import {
    SimpleGrid,
    useStyleConfig,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    AccordionIcon,
    useDisclosure,
    List,
    ListItem
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'
import LinksList from '@salesforce/retail-react-app/app/components/links-list'
import {WeChatModal} from '../../../components/wechat-modal'
import {Component} from '@salesforce/commerce-sdk-react/components'

export const PDCFooterLinksListMobile = ({regions, title, isSocial}) => {
    const components = regions?.[0]?.components || []

    const {isOpen: isWeChatOpen, onOpen: onWeChatOpen, onClose: onWeChatClose} = useDisclosure()

    const styles = useStyleConfig('Footer')

    if (isSocial) {
        const linkItems = components.map((link, index) => {
            const {fontColor = styles.link.color, fontSize = '12px', label} = link.data
            const isWeChat = label.toLowerCase() === 'wechat'

            return (
                <ListItem key={index} role="listitem">
                    {isWeChat ? (
                        <React.Fragment>
                            <Link
                                onClick={onWeChatOpen}
                                href={''}
                                {...styles.link}
                                color={fontColor}
                                fontSize={fontSize}
                                display={'inline-block'}
                            >
                                {label}
                            </Link>
                            <WeChatModal isOpen={isWeChatOpen} onClose={onWeChatClose} />
                        </React.Fragment>
                    ) : (
                        <Component
                            component={{
                                ...link,
                                fontColor,
                                fontSize,
                                textDecoration: 'none',
                                display: 'inline-block'
                            }}
                        />
                    )}
                </ListItem>
            )
        })

        return (
            <SimpleGrid as={List} columns={2} spacing={'10px'} my={'40px'} px={'10px'}>
                {linkItems}
            </SimpleGrid>
        )
    } else {
        const links = components.map(
            ({data: {url, label, fontSize = '12px', fontColor = styles.link.color}}) => ({
                href: url,
                text: label,
                styles: {
                    ...styles.link,
                    fontSize: fontSize,
                    color: fontColor,
                    display: 'inline-block',
                    padding: 0,
                    margin: '3px 10px'
                }
            })
        )

        return (
            <AccordionItem mt={'-1px'}>
                <AccordionButton
                    flex={1}
                    justifyContent={'space-between'}
                    textTransform={'uppercase'}
                    role={'heading'}
                    aria-level={2}
                    px={'10px'}
                >
                    {title}
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <LinksList links={links} />
                </AccordionPanel>
            </AccordionItem>
        )
    }
}

export default PDCFooterLinksListMobile

PDCFooterLinksListMobile.propTypes = {
    title: PropTypes.string.isRequired,
    isSocial: PropTypes.bool,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
