import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    Box,
    Text,
    AspectRatio,
    Link,
    LinkBox,
    LinkOverlay,
    useBreakpointValue,
    useStyleConfig
} from '@chakra-ui/react'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {DYNAMIC_IMAGE_WIDTHS} from '../../../constants.js'
import {useUrlFromLink} from '../../helpers/urlHelper'

/**
 * PDCHomeServicesPushTile component.
 * @typedef {Object} PDCHomeServicesPushTileProps
 * @property {string} title - Title of the tile.
 * @property {string} desc - Description of the tile.
 * @property {string} link - Link of the tile.
 * @property {string} linkLabel - Label of the link.
 * @property {object} smallImage - Small image object.
 * @property {object} mediumImage - Medium image object.
 * @property {object} largeImage - Large image object.
 * @property {object} xlargeImage - Extra large image object.
 * @property {object} xxlargeImage - Extra extra large image object.
 * @property {string} imageTitle - Title of the image.
 * @property {string} imageAlt - Alt of the image.
 * @property {object} custom - Custom object.
 * @returns {React.ReactElement} - PDCHomeServicesPushTile component.
 */
export const PDCHomeServicesPushTile = ({
    title,
    desc,
    link,
    linkLabel,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    custom
}) => {
    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    const updatedUrl = useUrlFromLink(custom?.link) || link
    const isLinkExternal = custom?.link?.type === 'EXTERNAL'
    const styles = useStyleConfig('Link', {})

    return (
        <LinkBox
            as="article"
            display="flex"
            justifyContent={'center'}
            alignItems={'flex-start'}
            textAlign={'center'}
            textDecoration="none"
            flex="1"
            px={[21, null, null, 55]}
        >
            <Box width={['100%', null, '25vw', '292px']}>
                <AspectRatio ratio={1} height={'100%'}>
                    <DynamicImage
                        src={`${currentImage.url}[?sw={width}]`}
                        widths={DYNAMIC_IMAGE_WIDTHS}
                        imageProps={{
                            width: '100%',
                            loading: 'auto',
                            fetchpriority: 'high',
                            alt: imageAlt === 'empty' ? '' : imageAlt || title,
                            title: imageTitle === 'empty' ? '' : imageTitle || title
                        }}
                    />
                </AspectRatio>
                <Text
                    variant="eyeBrowLarge"
                    mt={20}
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                />
                {desc && (
                    <Text
                        mt={10}
                        variant="bodySmall"
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                    />
                )}
                {link && linkLabel && (
                    <LinkOverlay
                        as={isLinkExternal ? Link : RouteLink}
                        href={updatedUrl}
                        to={updatedUrl}
                        {...styles}
                        display={'inline-block'}
                        mt={10}
                    >
                        <Text
                            as="span"
                            display={'block'}
                            variant="bodyBase1"
                            textDecoration="underline"
                            fontWeight="bold"
                        >
                            {linkLabel}
                        </Text>
                    </LinkOverlay>
                )}
            </Box>
        </LinkBox>
    )
}

PDCHomeServicesPushTile.propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    custom: PropTypes.shape({
        link: PropTypes.shape({
            target: PropTypes.string,
            type: PropTypes.string
        })
    })
}
