const buttonBaseStyle = {
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    _active: {
        color: 'white',
        bg: 'fullBlack',
        border: 'none'
    },
    _disabled: {
        opacity: 1
    },
    _focusVisible: {
        outline: '2px solid transparent',
        boxShadow: 'outline'
    }
}

export default {
    baseStyle: {
        bg: 'transparent',
        color: 'fullBlack',
        borderRadius: 'none',
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: 500,
        _loading: {
            opacity: 1
        }
    },
    variants: {
        primary: {
            ...buttonBaseStyle,
            bg: 'fullBlack',
            color: 'white',
            _hover: {
                color: 'white',
                backgroundColor: 'darkGray',
                textDecoration: 'none',
                _disabled: {
                    bg: 'borderGray'
                }
            },
            _disabled: {
                opacity: 1,
                color: 'white',
                bg: 'dimGray'
            },
            _loading: {
                backgroundColor: 'fullBlack'
            }
        },
        secondary: {
            ...buttonBaseStyle,
            color: 'fullBlack',
            border: '1px solid',
            borderColor: 'gray',
            bg: 'transparent',
            _hover: {
                backgroundColor: 'transparent',
                borderColor: 'darkGray',
                textDecoration: 'none',
                _disabled: {
                    borderColor: 'borderGray'
                }
            },
            _focus: {
                color: 'white',
                bg: 'fullBlack',
                borderColor: 'fullBlack'
            },
            _disabled: {
                color: 'darkGray',
                borderColor: 'borderGray'
            },
            _loading: {
                backgroundColor: 'transparent',
                border: '1px solid gray'
            }
        },
        tertiary: {
            ...buttonBaseStyle,
            color: 'fullBlack',
            bg: 'white',
            _hover: {
                color: 'white',
                backgroundColor: 'fullBlack',
                textDecoration: 'none',
                _disabled: {
                    color: 'darkGray',
                    bg: 'white'
                }
            },
            _disabled: {
                color: 'darkGray'
            },
            _loading: {
                backgroundColor: 'white'
            }
        },
        invertedOutline: {
            ...buttonBaseStyle,
            color: 'white',
            bg: 'transparent',
            border: '1px solid',
            borderColor: 'white',
            _hover: {
                color: 'fullBlack',
                backgroundColor: 'white',
                borderColor: 'transparent',
                textDecoration: 'none',
                _disabled: {
                    color: 'white',
                    borderColor: 'white'
                }
            },
            _focus: {
                color: 'white',
                bg: 'fullBlack',
                borderColor: 'fullBlack'
            },
            _loading: {
                backgroundColor: 'transparent'
            }
        },
        link: {
            ...buttonBaseStyle,
            color: 'fullBlack',
            textTransform: 'none',
            textDecoration: 'underline',
            fontFamily: 'body',
            fontWeight: 500,
            lineHeight: '110%',
            letterSpacing: '0.01em',
            display: 'inline',
            minWidth: 0,
            maxWidth: 'full',
            whiteSpace: 'normal',
            _loading: {
                backgroundColor: 'transparent'
            },
            _active: {
                color: 'fullBlack',
                bg: 'transparent'
            }
        },
        menuLink: {
            display: 'flex',
            width: 'full',
            justifyContent: 'flex-start',
            p: 16,
            textAlign: 'left',
            textDecoration: 'none',
            height: 'auto',
            whiteSpace: 'normal',
            border: '1px solid',
            borderColor: 'white',
            _focus: {
                bg: 'lightBeige',
                borderColor: 'beige',
                textDecoration: 'none'
            },
            _hover: {
                bg: 'lightBeige',
                borderColor: 'beige',
                textDecoration: 'none'
            },
            _activeLink: {
                bg: 'lightBeige',
                borderColor: 'beige'
            },
            fontSize: 'lg',
            lineHeight: 'short',
            letterSpacing: '0.01em'
        }
    },
    sizes: {
        inputElement: {
            height: '40px',
            width: '40px'
        },
        md: {
            height: '50px',
            minWidth: 10,
            px: '32px'
        },
        auto: {
            height: 'auto'
        }
    }
}
