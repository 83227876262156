import React from 'react'
import PropTypes from 'prop-types'
import {
    Text,
    Image,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import colors from '../../theme/foundations/colors'

export const WeChatModal = ({isOpen, onClose}) => {
    const intl = useIntl()

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textTransform={'uppercase'}>
                    {intl.formatMessage({
                        id: 'wechatmodal.title',
                        defaultMessage: 'FOLLOW US ON WECHAT'
                    })}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody mt={34}>
                    <Text variant={'bodyBase2'} color={colors.darkGray}>
                        {intl.formatMessage({
                            id: 'wechatmodal.text',
                            defaultMessage: 'Scan the QR code below to follow'
                        })}
                    </Text>
                    <Image
                        width={247}
                        height={247}
                        src={getAssetUrl('static/img/wechat_qr.png')}
                        alt={intl.formatMessage({
                            id: 'wechatmodal.imgalt',
                            defaultMessage: 'WeChat Vilebrequin QR Code'
                        })}
                    ></Image>
                    <Text variant={'bodyBase2'} color={colors.darkGray}>
                        {intl.formatMessage({
                            id: 'wechatmodal.brand',
                            defaultMessage: 'Vilebrequin Official'
                        })}
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

WeChatModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func
}
