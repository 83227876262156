export default {
    baseStyle: {
        link: {
            textDecoration: 'none',
            color: 'darkGray',
            textAlign: 'left',
            display: 'block',
            marginY: '3px',
            fontWeight: '400'
        },
        languageSelector: {
            border: 'none'
        }
    }
}
