import React from 'react'
import PropTypes from 'prop-types'
import {VStack} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'

/**
 * PDC Header Navigation Links
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Header Navigation Links component
 */
export const PDCCheckoutHeaderHelpLinks = ({regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    return (
        components && (
            <VStack as="ul" listStyleType="none" spacing={'16px'} align="flex-start">
                {components.map((component, index) => {
                    return (
                        <li key={component?.id || index}>
                            <Component component={component} />
                        </li>
                    )
                })}
            </VStack>
        )
    )
}

PDCCheckoutHeaderHelpLinks.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
