import React, {useState} from 'react'
import {
    Box,
    Text,
    useStyleConfig,
    Heading,
    createStylesContext,
    Image,
    Stack,
    Accordion,
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useIntl} from 'react-intl'

import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import colors from '../../theme/foundations/colors'
import {usePage} from '@salesforce/commerce-sdk-react'
import {PageContext, Component} from '@salesforce/commerce-sdk-react/components'
import {
    PDCFooterLinksListMobile,
    PDCFooterLinksListDesktop,
    PDCFooterLinksListCheckout,
    PDCFooterReinsurance
} from '../../page-designer/layouts'
import {PDCFooterReinsuranceIcon, PDCLink} from '../../page-designer/assets'
import FooterBottom from './footer-bottom'
import {useLocation} from 'react-router-dom'

const PAGEDESIGNER_TO_COMPONENT_MOBILE = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListMobile
}

const PAGEDESIGNER_TO_COMPONENT_DESKTOP = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListDesktop
}

const PAGEDESIGNER_TO_COMPONENT_CHECKOUT = {
    'commerce_assets.pdcLink': PDCLink,
    'commerce_layouts.pdcFooterLinksList': PDCFooterLinksListCheckout
}
const PAGEDESIGNER_TO_COMPONENT_REINSURANCE = {
    'commerce_assets.pdcFooterReinsuranceIcon': PDCFooterReinsuranceIcon,
    'commerce_layouts.pdcFooterReinsurance': PDCFooterReinsurance
}
const [StylesProvider] = createStylesContext('Footer')

/**
 * Footer Component
 *
 * This component renders the footer section of the application, including different layouts
 * for mobile, desktop, and checkout pages. It dynamically loads footer links and other
 * components based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <Footer />
 *
 * @returns {JSX.Element} The rendered footer component.
 */

const Footer = () => {
    const styles = useStyleConfig('Footer')
    const intl = useIntl()
    const isDesktop = useBreakpointValue({base: false, lg: true})

    const location = useLocation()

    const isCheckout = /\/checkout$/.test(location?.pathname)
    // Get the header page from page designer to display the navigation links
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}})
    const footerLinksListComponents = footerPage?.regions?.[0].components?.filter(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterLinksList'
    )
    const [contextValueMobile, setContextValueMobile] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_MOBILE
    })
    const [contextValueDesktop, setContextValueDesktop] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_DESKTOP
    })

    const [contextValueCheckout, setContextValueCheckout] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_CHECKOUT
    })

    const checkoutFooterStyle = isDesktop
        ? {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              pt: '70px'
          }
        : null
    return (
        <Box as="footer" {...styles.container}>
            {isCheckout ? null : <PreFooter></PreFooter>}
            <Box {...styles.content} bg={colors.lightBeige}>
                <StylesProvider value={styles}>
                    <Box sx={isCheckout ? checkoutFooterStyle : null}>
                        <Stack
                            direction={{base: 'column', lg: 'row'}}
                            spacing={'44px'}
                            pt={'44px'}
                            pb={{base: '44px', lg: '140px'}}
                            px={{base: '10px', lg: '20px'}}
                        >
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'}>
                                    <Heading
                                        as="h4"
                                        fontSize="3xl"
                                        textTransform={'uppercase'}
                                        mb={13}
                                    >
                                        {intl.formatMessage({
                                            id: 'footer.newsletter.title',
                                            defaultMessage: 'Newsletter'
                                        })}
                                    </Heading>
                                    <Text variant="bodyBase2" color={colors.darkGray} maxW={'280'}>
                                        {intl.formatMessage({
                                            id: 'footer.newsletter.info',
                                            defaultMessage:
                                                'Sign in to receive our latest news about collections, products, shows and collaborations'
                                        })}
                                    </Text>
                                    {/* TODO : IMPLEMENT NEWSLETTER
                                    <InputWithSubmit name="email" label="Email address" /> 
                                    */}
                                </Box>
                            )}
                            <Box flexBasis={isCheckout ? null : '33%'} alignSelf={'center'}>
                                <Image
                                    align={'center'}
                                    width={{base: '173', lg: '214'}}
                                    height={{base: '170', lg: '210'}}
                                    margin={'auto'}
                                    src={getAssetUrl('static/img/turtle.svg')}
                                    alt={intl.formatMessage({
                                        id: 'footer.turtle',
                                        defaultMessage: 'Turtle'
                                    })}
                                />
                            </Box>
                            {isCheckout ? null : (
                                <Box flexBasis={'33%'} textAlign={{base: 'left', lg: 'right'}}>
                                    {/* TODO: TO REMOVE ONCE REAL STARS RATING IS IMPLEMENTED */}
                                    <Text variant="bodyBase1">Stars rating</Text>
                                </Box>
                            )}
                        </Stack>
                        <Box px={{base: isCheckout ? '10px' : '0', lg: '20px'}} flexGrow={1}>
                            {isCheckout ? (
                                <PageContext.Provider value={contextValueCheckout}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === true
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box
                                                    key={index}
                                                    flex={'0 1 20%'}
                                                    mb={isDesktop ? '' : '44px'}
                                                >
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : isDesktop ? (
                                <PageContext.Provider value={contextValueDesktop}>
                                    <Stack direction={'row'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Box key={index} flex={'0 1 20%'}>
                                                    <Component component={footerLinksList} />
                                                </Box>
                                            ))}
                                    </Stack>
                                </PageContext.Provider>
                            ) : (
                                <PageContext.Provider value={contextValueMobile}>
                                    <Accordion allowToggle mb={'44px'}>
                                        {footerLinksListComponents
                                            ?.filter(
                                                (footerLinksList) =>
                                                    footerLinksList.data.checkoutLinks === false
                                            )
                                            ?.map((footerLinksList, index) => (
                                                <Component
                                                    key={index}
                                                    component={footerLinksList}
                                                />
                                            ))}
                                    </Accordion>
                                </PageContext.Provider>
                            )}
                        </Box>
                    </Box>
                    <FooterBottom />
                </StylesProvider>
            </Box>
        </Box>
    )
}

export default Footer

/**
 * PreFooter Component
 *
 * This component renders the pre-footer section, which includes reinsurance information.
 * It dynamically loads the reinsurance component based on the current page context.
 *
 * @component
 * @example
 * // Usage example:
 * <PreFooter />
 *
 * @returns {JSX.Element} The rendered pre-footer component.
 */

const PreFooter = () => {
    const {data: footerPage} = usePage({parameters: {pageId: 'footer'}})
    const footerReinsuranceComponent = footerPage?.regions?.[0].components?.find(
        (comp) => comp?.typeId === 'commerce_layouts.pdcFooterReinsurance'
    )
    const [contextValueReinsurance, setContextValueReinsurance] = useState({
        components: PAGEDESIGNER_TO_COMPONENT_REINSURANCE
    })
    return (
        <>
            {footerReinsuranceComponent && (
                <PageContext.Provider value={contextValueReinsurance}>
                    <Component component={footerReinsuranceComponent} />
                </PageContext.Provider>
            )}
        </>
    )
}
PreFooter.propTypes = {}
