import React, {useId} from 'react'
import {Link as RouteLink} from 'react-router-dom'
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import PropTypes from 'prop-types'
import {
    Divider,
    HStack,
    Flex,
    Text,
    Link,
    LinkBox,
    LinkOverlay,
    useBreakpointValue,
    useStyleConfig
} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {DYNAMIC_IMAGE_WIDTHS} from '../../../constants.js'
import {useUrlFromLink} from '../../helpers/urlHelper'

/**
 * @param {string} title - title of the component
 * @param {string} titleHeadingLevel - font size of the title
 * @param {string} titleFontColor - font color of the title
 * @param {string} subTitle - subtitle of the component
 * @param {string} linksBackgroundColor - background color of the links
 * @param {object} smallImage - small image
 * @param {object} mediumImage - medium image
 * @param {object} largeImage - large image
 * @param {object} xlargeImage - xlarge image
 * @param {object} xxlargeImage - xxlarge image
 * @param {string} imageTitle - image title
 * @param {string} imageAlt - image alt
 * @param {string} txtHorizontalPos - text horizontal position
 * @param {string} linksHorizontalPos - links horizontal position
 * @param {string} wholeComponentLink - whole component link
 * @param {object} regions - regions
 * @param {object} custom - custom attributes
 * @returns {JSX.Element} - PDCHomeFullScreenPushImage component
 */
export const PDCHomeFullScreenPushImage = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    linksBackgroundColor,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    txtHorizontalPos,
    linksHorizontalPos,
    wholeComponentLink,
    regions,
    custom
}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const txtPosFlex = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    }

    const titleId = useId()

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    const updatedUrl = useUrlFromLink(custom?.wholeComponentLink) || wholeComponentLink
    const isLinkExternal = custom?.wholeComponentLink?.type === 'EXTERNAL'
    const styles = useStyleConfig('Link', {})
    styles.textDecoration = 'none'
    styles._hover = {
        textDecoration: 'none'
    }

    return (
        <LinkBox
            css={[PDCHomeFullScreenPushImage.css]}
            aria-describedby={titleId}
            as="section"
            position="relative"
        >
            <DynamicImage
                src={`${currentImage?.url}[?sw={width}]`}
                widths={DYNAMIC_IMAGE_WIDTHS}
                position="relative"
                imageProps={{
                    width: '100%',
                    height: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    objectFit: 'cover',
                    alt: imageAlt === 'empty' ? '' : imageAlt ? imageAlt : title,
                    title: imageTitle === 'empty' ? '' : imageTitle ? imageTitle : title
                }}
            />
            <Flex
                alignItems="center"
                justifyContent={txtPosFlex[txtHorizontalPos]}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                p={['32px 10px', null, '32px 20px']}
            >
                <Flex
                    direction={['column', null, 'row']}
                    justifyContent={['center', null, txtPosFlex[txtHorizontalPos]]}
                    alignItems={[txtPosFlex[txtHorizontalPos], null, 'center']}
                    grow={1}
                    shrink={1}
                >
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: subTitle
                        }}
                        variant="eyeBrowSmall"
                        color={'fullBlack'}
                        mb={['10px', null, 0]}
                        mr={[0, null, '10px']}
                    />
                    <LinkOverlay
                        as={isLinkExternal ? Link : RouteLink}
                        to={updatedUrl}
                        href={updatedUrl}
                        _hover={{textDecoration: 'none'}}
                        {...styles}
                    >
                        <PDCTitle
                            title={title}
                            titleFontColor={titleFontColor}
                            titleHeadingLevel={titleHeadingLevel}
                            txtHorizontalPos={txtHorizontalPos}
                            id={titleId}
                            size="3xl"
                        />
                    </LinkOverlay>
                </Flex>
            </Flex>
            {components && components.length > 0 && (
                <Flex
                    alignItems="center"
                    justifyContent={txtPosFlex[linksHorizontalPos]}
                    position="absolute"
                    bottom="32px"
                    width="100%"
                    px={['10px', null, '20px']}
                >
                    <HStack
                        bg={linksBackgroundColor || 'white'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={0}
                        as="ul"
                        listStyleType={'none'}
                    >
                        {components.map((component, index) => {
                            return (
                                <HStack as="li" key={component?.id || index} gap={0}>
                                    <Component component={component} />
                                    {index !== components.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            color="gray"
                                            height="13px"
                                        />
                                    )}
                                </HStack>
                            )
                        })}
                    </HStack>
                </Flex>
            )}
        </LinkBox>
    )
}

PDCHomeFullScreenPushImage.css = css`
    .chakra-link {
        padding: 18px 20px;
        text-decoration: none;
        &:focus-visible {
            outline-offset: 0;
        }
    }
    li:first-child {
        .chakra-link {
            padding-left: 24px;
        }
    }
    li:last-child {
        .chakra-link {
            padding-right: 24px;
        }
    }
`

PDCHomeFullScreenPushImage.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    linksBackgroundColor: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    txtHorizontalPos: PropTypes.string.isRequired,
    linksHorizontalPos: PropTypes.string.isRequired,
    wholeComponentLink: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    ),
    custom: PropTypes.shape({
        wholeComponentLink: PropTypes.shape({
            target: PropTypes.string,
            type: PropTypes.string
        })
    })
}
