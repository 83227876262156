import button from './button'

export default {
    baseStyle: {
        color: 'fullBlack',
        fontSize: 'md',
        fontFamily: 'body',
        fontWeight: 500,
        lineHeight: '110%',
        letterSpacing: '0.01em',
        textDecoration: 'underline',
        _focusVisible: {
            boxShadow: 'none',
            outline: '3px solid rgba(66, 153, 225, 0.6)',
            outlineOffset: '2px'
        }
    },
    // New `variants` or `sizes` can be created depending on specific pages/sections
    variants: button.variants,
    sizes: button.sizes
}
