import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    Text,
    useStyleConfig,
    List,
    ListItem
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {Component} from '@salesforce/commerce-sdk-react/components'

export const PDCFooterLinksListCheckout = ({regions, title}) => {
    const components = regions?.[0]?.components || []

    const styles = useStyleConfig('Footer')
    const linkMarginBottom = {
        '&:not(:last-of-type)': {
            marginBottom: ' 10px'
        }
    }
    return (
        <Box>
            <Text
                role={'heading'}
                aria-level={2}
                variant={'bodyBase2'}
                fontSize={10}
                fontWeight={500}
                mb={'24px'}
                textTransform={'uppercase'}
            >
                {title}
            </Text>
            <List>
                {components.map((link, index) => {
                    const {fontColor = styles.link.color, fontSize = '12px'} = link.data
                    return (
                        <ListItem key={index} sx={linkMarginBottom} role="listitem">
                            <Component
                                component={{
                                    ...link,
                                    fontColor,
                                    fontSize,
                                    textDecoration: 'none',
                                    paddingY: '3px'
                                }}
                            />
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default PDCFooterLinksListCheckout

PDCFooterLinksListCheckout.propTypes = {
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
