import React from 'react'
import PropTypes from 'prop-types'

import {
    Box,
    Text,
    useStyleConfig,
    useDisclosure,
    List,
    ListItem
} from '@salesforce/retail-react-app/app/components/shared/ui'
import Link from '@salesforce/retail-react-app/app/components/link'
import {WeChatModal} from '../../../components/wechat-modal'
import {Component} from '@salesforce/commerce-sdk-react/components'

export const PDCFooterLinksListDesktop = ({regions, title}) => {
    const components = regions?.[0]?.components || []
    const {isOpen: isWeChatOpen, onOpen: onWeChatOpen, onClose: onWeChatClose} = useDisclosure()

    const styles = useStyleConfig('Footer')
    const linkMarginBottom = {
        '&:not(:last-of-type)': {
            marginBottom: ' 2px'
        }
    }
    return (
        <Box>
            <Text
                role={'heading'}
                aria-level={2}
                variant={'bodyBase2'}
                fontSize={10}
                fontWeight={500}
                mb={'20px'}
                textTransform={'uppercase'}
            >
                {title}
            </Text>
            <List>
                {components.map((link, index) => {
                    const {fontColor = styles.link.color, fontSize = '12px', label} = link.data
                    const isWeChat = label.toLowerCase() === 'wechat'

                    return (
                        <ListItem key={index} sx={linkMarginBottom} role="listitem">
                            {isWeChat ? (
                                <React.Fragment>
                                    <Link
                                        onClick={onWeChatOpen}
                                        href={''}
                                        {...styles.link}
                                        color={fontColor}
                                        fontSize={fontSize}
                                        display={'inline-block'}
                                    >
                                        {label}
                                    </Link>
                                    <WeChatModal isOpen={isWeChatOpen} onClose={onWeChatClose} />
                                </React.Fragment>
                            ) : (
                                <Component
                                    component={{
                                        ...link,
                                        fontColor,
                                        fontSize,
                                        textDecoration: 'none',
                                        display: 'inline-block'
                                    }}
                                />
                            )}
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    )
}

export default PDCFooterLinksListDesktop

PDCFooterLinksListDesktop.propTypes = {
    title: PropTypes.string.isRequired,
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
