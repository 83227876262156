import React, {useId} from 'react'
import PropTypes from 'prop-types'
import {Box, Flex, Text, Stack, useBreakpointValue} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {DYNAMIC_IMAGE_WIDTHS} from '../../../constants.js'

/**
 * PDC Home Dyptich
 * @param {string} title - Title of the component
 * @param {string} titleHeadingLevel - Title font size
 * @param {string} titleFontColor - Title font color
 * @param {string} subTitle - Subtitle
 * @param {string} desc - Description
 * @param {string} backgroundColor - Background color
 * @param {string} textColor - Text color
 * @param {object} smallImage - Small image
 * @param {object} mediumImage - Medium image
 * @param {object} largeImage - Large image
 * @param {object} xlargeImage - Xlarge image
 * @param {object} xxlargeImage - Xxlarge image
 * @param {string} imageTitle - Image title
 * @param {string} imageAlt - Image alt
 * @param {string} txtHorizontalPos - Text horizontal position
 * @param {string} imgPlacement - Image placement
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Home Dyptich component
 */
export const PDCHomeDyptich = ({
    title,
    titleHeadingLevel,
    titleFontColor,
    subTitle,
    desc,
    backgroundColor,
    textColor,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    txtHorizontalPos,
    imgPlacement,
    regions
}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const txtPosFlex = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end'
    }

    const titleId = useId()
    const currentRatio = useBreakpointValue({base: 207 / 275, md: 143 / 180})

    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    const txtBlock = (
        <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems={txtPosFlex[txtHorizontalPos]}
            width={['100%', '100%', '50%']}
            py={['70px', '70px', '20px']}
            px={['10px', '10px', '40px']}
        >
            <Text
                dangerouslySetInnerHTML={{
                    __html: subTitle
                }}
                variant="eyeBrowLarge"
                color={textColor ? textColor : 'fullBlack'}
                mb={['24px', '24px', '34px']}
            />
            <PDCTitle
                width={['90%', null, '100%']}
                title={title}
                titleFontColor={titleFontColor}
                titleHeadingLevel={titleHeadingLevel}
                txtHorizontalPos={txtHorizontalPos}
                textColor={textColor}
                id={titleId}
                mb={['24px', '24px', '34px']}
                size={['5xl', null, null, '6xl']}
            />
            <Text
                dangerouslySetInnerHTML={{
                    __html: desc
                }}
                variant="bodyLarge2"
                width={['90%', null, '304px']}
                textAlign={txtHorizontalPos}
            />

            {components && (
                <Stack
                    as="ul"
                    listStyleType="none"
                    pt={['24px', '24px', '34px']}
                    width={['100%', null, '304px']}
                    gap={'16px'}
                >
                    {components.map((component, index) => {
                        component.backgroundColor = backgroundColor
                        component.textColor = textColor
                        component.txtHorizontalPos = txtHorizontalPos
                        component.width = 'full'
                        return (
                            <li key={component?.id || index}>
                                <Component component={component} />
                            </li>
                        )
                    })}
                </Stack>
            )}
        </Flex>
    )

    const imgBlock = currentImage && (
        <Box width={['100%', '100%', '50%']} position="relative">
            <DynamicImage
                src={`${currentImage.url}[?sw={width}]`}
                widths={DYNAMIC_IMAGE_WIDTHS}
                imageProps={{
                    width: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    alt: imageAlt === 'empty' ? '' : imageAlt ? imageAlt : title,
                    title: imageTitle === 'empty' ? '' : imageTitle ? imageTitle : title
                }}
            />
        </Box>
    )

    return (
        <Box
            as="section"
            aria-describedby={titleId}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
            backgroundColor={backgroundColor}
            color={textColor}
        >
            {imgPlacement === 'left (top)' ? (
                <>
                    {imgBlock}
                    {txtBlock}
                </>
            ) : (
                <>
                    {txtBlock}
                    {imgBlock}
                </>
            )}
        </Box>
    )
}

PDCHomeDyptich.propTypes = {
    title: PropTypes.string.isRequired,
    titleHeadingLevel: PropTypes.string,
    titleFontColor: PropTypes.string,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    txtHorizontalPos: PropTypes.string,
    imgPlacement: PropTypes.string,
    custom: PropTypes.shape({
        imageMobile: PropTypes.string,
        imageTablet: PropTypes.string,
        imageDesktop: PropTypes.string
    }),
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
