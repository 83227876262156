import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

// Components
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Link,
    List,
    ListItem,
    Text,

    // Hooks
    useBreakpointValue
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {ChevronUpIcon, ChevronDownIcon} from '../custom-icons'
import {CATEGORY_DISPLAY_NAME_FIELD} from './constants'

/**
 * Navigation menu level 3 categories component
 *
 * @param {Object} props
 * @param {Object} props.item
 * @param {string} props.itemsKey
 * @param {boolean} props.isOpen
 * @returns {React.Component}
 */
const ListItemsLvl3 = ({item, itemsKey, isOpen}) => {
    const intl = useIntl()
    const itemsLvl3 = item[itemsKey] || []
    const isMobile = useBreakpointValue({
        base: true,
        md: false
    })

    return (
        <List>
            {itemsLvl3.map((itemLvl3, i) => {
                const itemLvl3Name = itemLvl3?.[CATEGORY_DISPLAY_NAME_FIELD] || itemLvl3?.name
                return (
                    <ListItem key={itemLvl3.id}>
                        <Link
                            as={RouteLink}
                            to={categoryUrlBuilder(itemLvl3)}
                            textDecoration={'none'}
                            display={'block'}
                            tabIndex={isOpen ? 0 : -1}
                        >
                            <Text
                                variant={isMobile ? 'bodyBase2' : 'bodySmall'}
                                color="darkGray"
                                p={[i === 0 ? '0 0 7px' : '7px 0 7px', null, '3px 0 3px']}
                            >
                                {itemLvl3Name}
                            </Text>
                        </Link>
                    </ListItem>
                )
            })}
            <ListItem>
                <Link
                    as={RouteLink}
                    to={categoryUrlBuilder(item)}
                    display={'block'}
                    tabIndex={isOpen ? 0 : -1}
                >
                    <Text
                        variant={isMobile ? 'bodyBase2' : 'bodySmall'}
                        fontWeight={'500'}
                        color="darkGray"
                        py={['7px', null, '3px']}
                    >
                        {intl.formatMessage(
                            {
                                id: 'drawer_menu.link.shop_all',
                                defaultMessage: 'View all {name}'
                            },
                            {
                                name: ''
                            }
                        )}
                    </Text>
                </Link>
            </ListItem>
        </List>
    )
}

/**
 * Navigation menu level 2 categories component for mobile
 *
 * @param {Object} props
 * @param {Object} props.item
 * @param {string} props.itemsKey
 * @param {Function} props.itemsFilter
 * @returns {React.Component}
 */
export const DrawerMenuLvl2CategoriesMobile = ({item, itemsKey, itemsFilter, ...props}) => {
    const items = item[itemsKey] || []

    const filter = (item) =>
        typeof itemsFilter === 'function' ? itemsFilter(item) : !!item[itemsFilter]

    return (
        <Box {...props}>
            <Accordion allowMultiple={true} flex={1} overflowY={'auto'} mt="-1">
                {items.filter(filter).map((itemLvl2) => {
                    const {id, name} = itemLvl2
                    const itemName = itemLvl2?.[CATEGORY_DISPLAY_NAME_FIELD] || name

                    return (
                        <AccordionItem key={id}>
                            {({isExpanded}) => (
                                <>
                                    {/* Heading */}
                                    <AccordionButton
                                        p="20px"
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        textTransform={'uppercase'}
                                    >
                                        <Text variant="eyeBrowSmall" role="heading" aria-level="3">
                                            {itemName}
                                        </Text>
                                        {isExpanded ? (
                                            <ChevronUpIcon boxSize={3} />
                                        ) : (
                                            <ChevronDownIcon boxSize={3} />
                                        )}
                                    </AccordionButton>

                                    {/* Child Items */}
                                    {isExpanded && (
                                        <Box mt="-3px">
                                            <AccordionPanel p="3px 20px 13px">
                                                <ListItemsLvl3
                                                    {...props}
                                                    item={itemLvl2}
                                                    itemsKey={itemsKey}
                                                />
                                            </AccordionPanel>
                                        </Box>
                                    )}
                                </>
                            )}
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </Box>
    )
}

/**
 * Navigation menu level 2 categories component for desktop
 *
 * @param {Object} props
 * @param {Object} props.item
 * @param {string} props.itemsKey
 * @param {Function} props.itemsFilter
 * @param {boolean} props.isOpen
 * @returns {React.Component}
 */
export const DrawerMenuLvl2CategoriesDesktop = ({
    item,
    itemsKey,
    itemsFilter,
    isOpen,
    ...props
}) => {
    const intl = useIntl()
    const items = item[itemsKey] || []
    const itemName = item?.[CATEGORY_DISPLAY_NAME_FIELD] || item?.name

    const filter = (item) =>
        typeof itemsFilter === 'function' ? itemsFilter(item) : !!item[itemsFilter]

    return (
        <Box {...props} width="349px">
            <Link
                as={RouteLink}
                to={categoryUrlBuilder(item)}
                display={'block'}
                tabIndex={isOpen ? 0 : -1}
            >
                <Text variant={'bodySmall'} fontWeight="500">
                    {intl.formatMessage(
                        {
                            id: 'drawer_menu.link.shop_all',
                            defaultMessage: 'View all {name}'
                        },
                        {
                            name: itemName || ''
                        }
                    )}
                </Text>
            </Link>
            {items.filter(filter).map((itemLvl2) => {
                const {id, name} = itemLvl2
                const itemLvl2Name = itemLvl2?.[CATEGORY_DISPLAY_NAME_FIELD] || name

                return (
                    <Box key={id}>
                        {/* Heading */}
                        <Text
                            variant="descriptor"
                            textTransform={'uppercase'}
                            role="heading"
                            aria-level="3"
                            mt="34px"
                            mb="7px"
                        >
                            {itemLvl2Name}
                        </Text>

                        {/* Child Items */}
                        <ListItemsLvl3
                            {...props}
                            item={itemLvl2}
                            itemsKey={itemsKey}
                            isOpen={isOpen}
                        />
                    </Box>
                )
            })}
        </Box>
    )
}

DrawerMenuLvl2CategoriesMobile.propTypes = {
    item: PropTypes.object.isRequired,
    itemsKey: PropTypes.string.isRequired,
    itemsFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

DrawerMenuLvl2CategoriesDesktop.propTypes = {
    item: PropTypes.object.isRequired,
    itemsKey: PropTypes.string.isRequired,
    itemsFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isOpen: PropTypes.bool
}

ListItemsLvl3.propTypes = {
    item: PropTypes.object.isRequired,
    itemsKey: PropTypes.string,
    itemsCountKey: PropTypes.string,
    isOpen: PropTypes.bool
}
