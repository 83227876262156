import React from 'react'
import PropTypes from 'prop-types'

// Components
import {Box, Text, useStyleConfig} from '@salesforce/retail-react-app/app/components/shared/ui'

import {LinkBox, LinkOverlay} from '@chakra-ui/react'

import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'

import {
    CATEGORY_BOTTOM_CONTENT_IMAGE_FIELD,
    CATEGORY_BOTTOM_CONTENT_IMAGE_TITLE_FIELD,
    CATEGORY_BOTTOM_CONTENT_IMAGE_ALT_FIELD,
    CATEGORY_BOTTOM_CONTENT_TITLE_FIELD,
    CATEGORY_BOTTOM_CONTENT_DESC_FIELD,
    CATEGORY_BOTTOM_CONTENT_LINKS_LABEL_FIELD,
    CATEGORY_BOTTOM_CONTENT_LINKS_URL_FIELD
} from './constants'

/**
 * Drawer menu footer of level 2 panel on mobile
 *
 * @param {Object} item - category item
 *
 * @returns {ReactElement}
 */
const DrawerMenuLvl2Footer = ({item, isOpen, ...props}) => {
    const image = item[CATEGORY_BOTTOM_CONTENT_IMAGE_FIELD]
    const imageTitle = item[CATEGORY_BOTTOM_CONTENT_IMAGE_TITLE_FIELD]
    const imageAlt = item[CATEGORY_BOTTOM_CONTENT_IMAGE_ALT_FIELD]
    const title = item[CATEGORY_BOTTOM_CONTENT_TITLE_FIELD]
    const description = item[CATEGORY_BOTTOM_CONTENT_DESC_FIELD]
    const linkURL = item[CATEGORY_BOTTOM_CONTENT_LINKS_URL_FIELD]
    const linkLabel = item[CATEGORY_BOTTOM_CONTENT_LINKS_LABEL_FIELD]
    const styles = useStyleConfig('Link', {})

    return (
        <LinkBox textAlign="center" position="relative" {...props}>
            <Box>
                {image && (
                    <DynamicImage
                        src={`${image}[?sw={width}]`}
                        widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                        imageProps={{
                            width: '100%',
                            loading: 'auto',
                            fetchpriority: 'high',
                            alt: imageAlt === 'empty' ? '' : imageAlt || title,
                            title: imageTitle === 'empty' ? '' : imageTitle || title
                        }}
                    />
                )}
            </Box>

            {title && (
                <Text fontFamily="heading" fontSize="md" lineHeight="12px" mt="10px">
                    {title}
                </Text>
            )}
            {description && (
                <Text variant="bodySmall" color="darkGray" mt="2px">
                    {description}
                </Text>
            )}
            {linkURL && (
                <Box mt="10px">
                    <LinkOverlay href={linkURL} tabIndex={isOpen ? 0 : -1} {...styles}>
                        {linkLabel && (
                            <Text variant="bodyBase1" as="span" textDecoration="underline">
                                {linkLabel}
                            </Text>
                        )}
                    </LinkOverlay>
                </Box>
            )}
        </LinkBox>
    )
}

DrawerMenuLvl2Footer.propTypes = {
    item: PropTypes.object,
    isOpen: PropTypes.bool
}

export default DrawerMenuLvl2Footer
