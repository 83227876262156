import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import {
    Grid,
    GridItem,
    Link,
    Text,
    useBreakpointValue,
    useStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {LinkBox, LinkOverlay, Skeleton} from '@chakra-ui/react'

import {usePage} from '@salesforce/commerce-sdk-react'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import usePDCResponsiveElement from '../../hooks/use-pdc-responsive-element'
import {useUrlFromLink} from '../../page-designer/helpers/urlHelper'

/**
 * Page link component used in SEO Menu
 *
 * @param {object} props - props object
 * @param {string} props.pageId - the page id to link to
 */
export const PageLink = ({pageId, ...props}) => {
    const {data: page} = usePage({parameters: {pageId: pageId}})
    const url = useUrlFromLink({type: 'PAGE', target: page?.id})

    return (
        <Link href={url} {...props}>
            {page?.name}
        </Link>
    )
}

/**
 * Navigation menu level 2 Page Designer page component
 *
 * @param {object} props - props object
 * @param {string} props.pageId - the page id to link to
 * @param {boolean} props.isOpen - is the drawer open
 * @returns {React.ReactElement}
 */
const DrawerMenuLvl2PDPage = ({pageId, isOpen}) => {
    const {data: page, isLoading: loading} = usePage({parameters: {pageId: pageId}})
    const aboutStoryBlockDefine = page?.regions[0]?.components?.find(
        (component) => component.typeId === 'commerce_layouts.pdcAboutStoryBlockDefine'
    )
    // Get current image based on breakpoint
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: aboutStoryBlockDefine?.data?.smallImage,
            mediumEl: aboutStoryBlockDefine?.data?.mediumImage,
            largeEl: aboutStoryBlockDefine?.data?.largeImage,
            xlargeEl: aboutStoryBlockDefine?.data?.xlargeImage,
            xxlargeEl: aboutStoryBlockDefine?.data?.xxlargeImage
        })
    )
    const url = useUrlFromLink({type: 'PAGE', target: page?.id})
    const styles = useStyleConfig('Link')

    return (
        <LinkBox as="article" textAlign="center" height={'100%'} pb={['10px', null, '14px']}>
            <Skeleton
                isLoaded={!loading && currentImage}
                minHeight="200px"
                minWidth="100%"
                startColor="lightBlue"
                endColor="white"
            >
                <DynamicImage
                    src={`${currentImage?.url}[?sw={width}]`}
                    widths={[320, 480, 680, 768, 1024, 1200, 1440, 1680, 1920, 2520]}
                    height={['240px', null, '210px']}
                    imageProps={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        loading: 'eager',
                        alt:
                            aboutStoryBlockDefine?.data?.imageAlt === 'empty'
                                ? ''
                                : aboutStoryBlockDefine?.data?.imageAlt ||
                                  aboutStoryBlockDefine?.data?.title,
                        title:
                            aboutStoryBlockDefine?.data?.imageTitle === 'empty'
                                ? ''
                                : aboutStoryBlockDefine?.data?.imageTitle ||
                                  aboutStoryBlockDefine?.data?.title
                    }}
                />
                <LinkOverlay
                    as={RouteLink}
                    to={url}
                    tabIndex={isOpen ? 0 : -1}
                    mt={['10px', null, '8px']}
                    display={'block'}
                    {...styles}
                    textDecoration={'none'}
                >
                    <Text
                        as="span"
                        fontFamily="heading"
                        textTransform={'uppercase'}
                        fontSize="12px"
                        lineHeight={1}
                        display={'block'}
                    >
                        {aboutStoryBlockDefine?.data?.title}
                    </Text>
                </LinkOverlay>
                {aboutStoryBlockDefine?.data?.subTitle && (
                    <Text variant="bodySmall" color="darkGray" mt="2px">
                        {aboutStoryBlockDefine?.data?.subTitle}
                    </Text>
                )}
            </Skeleton>
        </LinkBox>
    )
}

/**
 * Navigation menu level 2 Page Designer pages component
 *
 * @param {object} props - props object
 * @param {object} props.item - the item to display
 * @param {boolean} props.isOpen - is the drawer open
 * @returns {React.ReactElement}
 */
export const DrawerMenuLvl2PDPages = ({item, isOpen, ...props}) => {
    const pageIDS = item.regions[0]?.components || []

    return (
        <Grid templateColumns={'repeat(2, 1fr)'} gap="10px" {...props}>
            {pageIDS.map((pageID, i) => {
                return (
                    <GridItem key={pageID?.id} colSpan={i % 3 === 2 ? 2 : 1}>
                        <DrawerMenuLvl2PDPage pageId={pageID?.data?.page} isOpen={isOpen} />
                    </GridItem>
                )
            })}
        </Grid>
    )
}

DrawerMenuLvl2PDPages.propTypes = {
    item: PropTypes.object.isRequired,
    isOpen: PropTypes.bool
}

PageLink.propTypes = {
    pageId: PropTypes.string.isRequired,
    props: PropTypes.object
}

DrawerMenuLvl2PDPage.propTypes = {
    pageId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool
}

export default DrawerMenuLvl2PDPages
