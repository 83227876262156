import React, {useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Text,
    useBreakpointValue
} from '@chakra-ui/react'
import {Component} from '@salesforce/commerce-sdk-react/components'
import {useLocation} from 'react-router-dom'
import {ChevronDownIcon, ChevronRightIcon, ChevronUpIcon} from '../../../components/custom-icons'

/**
 * PDC Accordion Block : Wrapper for the legal pages main region
 * It renders accordions on mobile and tabs on tablet/desktop
 * Its children are PDCAccordion and possible grand-children PDCInnerAccordion
 *
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Anchors Block component
 */
export const PDCAccordionBlock = ({regions}) => {
    const {hash} = useLocation()
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const isMobile = useBreakpointValue({base: true, md: false})

    // Get index of the component where anchorId is equal to hash
    const anchorComponentIndex = components.findIndex((component) => {
        return component?.data?.anchorId === hash?.replace('#', '')
    })

    // Create a ref for each AccordionItem
    const accordionRefs = useRef([])

    // State to track if the entire page is rendered
    const [pageRendered, setPageRendered] = useState(false)

    // Scroll to the component with the anchorId that matches the hash
    useEffect(() => {
        if (isMobile && anchorComponentIndex !== -1 && pageRendered) {
            // Use setTimeout to ensure the scroll happens after the component and its children are fully rendered
            setTimeout(() => {
                const element = accordionRefs.current[anchorComponentIndex]
                const elementPosition = element.getBoundingClientRect().top + window.scrollY

                // Calculate the offset position considering the header and banner
                const headerHeight = document.querySelector('header#header').offsetHeight
                const bannerElement = document.getElementById('headerBanner')
                const bannerHeight = bannerElement ? bannerElement.offsetHeight : 0
                const offsetPosition = elementPosition - headerHeight - bannerHeight

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                })
            }, 0)
        }
    }, [isMobile, anchorComponentIndex, pageRendered])

    // Use another useEffect to set pageRendered to true after the first render
    useEffect(() => {
        const handleLoad = () => {
            setPageRendered(true)
        }

        // Listen for the load event to ensure all resources are loaded
        window.addEventListener('load', handleLoad)

        // Cleanup the event listener
        return () => {
            window.removeEventListener('load', handleLoad)
        }
    }, [])

    return (
        <Box p={['24px 10px', '24px 40px', '32px 40px']} backgroundColor={'whiteSmoke'}>
            {isMobile && (
                <Box backgroundColor={'white'}>
                    <Accordion
                        allowToggle
                        my="-1"
                        defaultIndex={[anchorComponentIndex]}
                        as="ul"
                        listStyleType="none"
                    >
                        {components.map((component, idx) => {
                            return (
                                <AccordionItem
                                    key={component?.id || idx}
                                    mb="-1"
                                    borderWidth={0}
                                    sx={{'&:last-of-type': {borderBottomWidth: '0'}}}
                                    ref={(el) => (accordionRefs.current[idx] = el)}
                                    as="li"
                                >
                                    {({isExpanded}) => (
                                        <Box id={component?.data?.anchorId}>
                                            <Heading as="h2">
                                                <AccordionButton
                                                    borderBottomWidth="0"
                                                    backgroundColor={isExpanded ? 'blue' : 'white'}
                                                    _hover={
                                                        isExpanded
                                                            ? {backgroundColor: 'blue'}
                                                            : {backgroundColor: 'white'}
                                                    }
                                                    p="20px 24px"
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={'space-between'}
                                                    color={isExpanded ? 'white' : 'fullBlack'}
                                                >
                                                    <Text
                                                        as="span"
                                                        align="left"
                                                        variant="eyeBrowLarge"
                                                        mr="10px"
                                                    >
                                                        {component?.data?.title}
                                                    </Text>
                                                    {isExpanded ? (
                                                        <ChevronUpIcon boxSize="12px" />
                                                    ) : (
                                                        <ChevronDownIcon boxSize="12px" />
                                                    )}
                                                </AccordionButton>
                                            </Heading>
                                            <AccordionPanel p="24px 16px" as="section">
                                                <Component component={component} />
                                            </AccordionPanel>
                                        </Box>
                                    )}
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                </Box>
            )}
            {!isMobile && (
                <Tabs
                    orientation="vertical"
                    defaultIndex={anchorComponentIndex !== -1 ? anchorComponentIndex : 0}
                >
                    <TabList backgroundColor={'white'} w="33%" borderWidth={'0'} as="ul">
                        {components.map((component, index) => {
                            return (
                                <Tab
                                    key={component?.id || index}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    textAlign={'left'}
                                    textStyle="eyeBrowLarge"
                                    fontSize={'14px'}
                                    p="20px 24px"
                                    _selected={{color: 'white', backgroundColor: 'blue'}}
                                    as="li"
                                >
                                    {component?.data?.title} <ChevronRightIcon boxSize="12px" />
                                </Tab>
                            )
                        })}
                    </TabList>
                    <TabPanels
                        ml={[null, null, '20px', '57px']}
                        backgroundColor={'white'}
                        borderWidth={'1px'}
                        borderColor="#F0F0F0"
                        as="ul"
                        listStyleType={'none'}
                    >
                        {components.map((component, index) => {
                            return (
                                <TabPanel key={component?.id || index} p="24px" as="li">
                                    <Component component={component} />
                                </TabPanel>
                            )
                        })}
                    </TabPanels>
                </Tabs>
            )}
        </Box>
    )
}

PDCAccordionBlock.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
