import React from 'react'
import PropTypes from 'prop-types'
import {Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import Alert from '../Alert'
import MandatoryNote from '../../../forms/mandatory-note'

/**
 * Renders a form with a fieldset, including an optional error alert and mandatory note.
 *
 * This component is designed to wrap form elements within a fieldset, providing accessibility
 * features such as a screen-reader-only legend. It also displays an error message if provided
 * and includes a note about mandatory fields if specified.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.legendText - The text for the fieldset legend, used for accessibility.
 * @param {string} [props.error] - An optional error message to display within an alert.
 * @param {boolean} [props.isMandatoryNote=true] - Flag to include a mandatory note within the form.
 * @param {React.ReactNode} props.children - The form elements to be rendered within the fieldset.
 * @param {Object} [formProps] - Additional props to be spread onto the form element.
 * @returns {JSX.Element} - A JSX element representing the form with fieldset.
 */
const FieldsetForm = ({legendText, error, isMandatoryNote = true, children, ...formProps}) => {
    return (
        <form {...formProps} noValidate>
            <Stack as="fieldset" spacing={24}>
                {/* ADA legend */}
                <legend className="sr-only">{legendText}</legend>

                {/* BD error message */}
                {error && <Alert status="error" description={error} />}

                {/* Form content */}
                {isMandatoryNote ? (
                    <Stack spacing={16}>
                        <MandatoryNote />
                        <Stack spacing={24}>{children}</Stack>
                    </Stack>
                ) : (
                    children
                )}
            </Stack>
        </form>
    )
}

FieldsetForm.propTypes = {
    legendText: PropTypes.string.isRequired,
    error: PropTypes.string,
    isMandatoryNote: PropTypes.bool,
    children: PropTypes.node.isRequired
}

export default FieldsetForm
