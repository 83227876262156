import React from 'react'
import PropTypes from 'prop-types'
import {Image} from '@salesforce/retail-react-app/app/components/shared/ui'

/**
 * PDCHomeServicesPushTile component.
 * @typedef {Object} PDCFooterPaymentIconProps
 * @property {object} paymentIcon - Payment icon
 * @property {string} name - Name of payment mode

 * @returns {React.ReactElement} - PDCHomeServicesPushTile component.
 */
export const PDCFooterPaymentIcon = ({paymentIcon, name}) => {
    return <Image src={paymentIcon.url} alt={name} height={'32px'} width={'32px'} />
}

PDCFooterPaymentIcon.propTypes = {
    paymentIcon: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
}
