import React from 'react'
import {Text} from '@salesforce/retail-react-app/app/components/shared/ui'
import {FormattedMessage} from 'react-intl'

/**
 * Displays a note indicating required fields.
 *
 * This component renders a text element aligned to the right, with a small body text variant
 * and dark gray color. It uses the `FormattedMessage` component from `react-intl` to support
 * internationalization, displaying a message about required fields.
 *
 * @component
 * @returns {JSX.Element} - A JSX element that displays the mandatory fields note.
 */
const MandatoryNote = () => {
    return (
        <Text textAlign="right" variant="bodyXSmall" color="darkGray">
            <FormattedMessage
                defaultMessage="*Required fields"
                id="mandatory_note.required_fields"
            />
        </Text>
    )
}

export default MandatoryNote
