import {useIntl} from 'react-intl'
import {COUNTRY_TRANSLATIONS} from '../components/forms/country-list'
import {findDialCodeByCountry} from '../utils/utils'

// Function to sort countries by label
const sortCountriesByLabel = (countries) => {
    return [...countries].sort((a, b) => a.label.localeCompare(b.label))
}

// Custom hook to get countries by local translations and sort by label
const useCountryList = ({isSorted = true, hasDial = false} = {}) => {
    const intl = useIntl()

    let countryCodes = Object.keys(COUNTRY_TRANSLATIONS).map((key) => ({
        value: key,
        label: intl.formatMessage(COUNTRY_TRANSLATIONS[key])
    }))

    if (hasDial) {
        countryCodes = countryCodes.filter((opt) => findDialCodeByCountry(opt.value) !== '')
    }

    return isSorted ? sortCountriesByLabel(countryCodes) : countryCodes
}

export default useCountryList
