export const NAV_MENU_CONFIG_PDC_PAGE_ID = 'navmenuconfig'
export const NAV_MENU_ITEMS_FILTER = 'c_showInMenu'
export const CATEGORY_DISPLAY_NAME_FIELD = 'c_seoDisplayName'
export const CATEGORY_BOTTOM_CONTENT_IMAGE_FIELD = 'c_navMenuImage'
export const CATEGORY_BOTTOM_CONTENT_IMAGE_TITLE_FIELD = 'c_navMenuImageTitle'
export const CATEGORY_BOTTOM_CONTENT_IMAGE_ALT_FIELD = 'c_navMenuImageAlt'
export const CATEGORY_BOTTOM_CONTENT_TITLE_FIELD = 'c_navMenuTitle'
export const CATEGORY_BOTTOM_CONTENT_DESC_FIELD = 'c_navMenuDescription'
export const CATEGORY_BOTTOM_CONTENT_LINKS_LABEL_FIELD = 'c_navMenuLinkLabel'
export const CATEGORY_BOTTOM_CONTENT_LINKS_URL_FIELD = 'c_navMenuLinkUrl'
