import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {HStack, Link, Divider, Flex} from '@chakra-ui/react'

/**
 * PDC Anchors Block
 * @param {object} regions - Regions
 * @returns {JSX.Element} - PDC Anchors Block component
 */
export const PDCAnchorsBlock = ({regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    const [titles, setTitles] = useState([])

    // Get titles of each component in the DOM
    useEffect(() => {
        const getTitles = () => {
            const newTitles = []
            components.forEach((anchor) => {
                const id = anchor.data.componentId
                newTitles.push(document.getElementById(id)?.querySelector('.pdc-title')?.innerText)
            })
            setTitles(newTitles)
        }
        window.addEventListener('load', getTitles)
        return () => {
            window.removeEventListener('load', getTitles)
        }
    }, [components])

    return (
        components && (
            <HStack
                gap="0"
                justifyContent={'center'}
                mt="1rem"
                mb="64px"
                px={['10px', null, '20px']}
                as="ul"
                listStyleType={'none'}
            >
                {components.map((child, i) => (
                    <Flex as="li" key={i}>
                        <Link href={`#${child.data.componentId}`} textAlign={'center'}>
                            {titles[i]}
                        </Link>
                        {i !== components.length - 1 && (
                            <Divider orientation="vertical" color="gray" height="13px" mx="24px" />
                        )}
                    </Flex>
                ))}
            </HStack>
        )
    )
}

PDCAnchorsBlock.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
