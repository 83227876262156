const fontSizes = {
    xs: '0.625rem', // 10px
    sm: '0.688rem', // 11px
    md: '0.75rem', // 12px
    lg: '0.813rem', // 13px
    xl: '0.875rem', // 14px
    '2xl': '1rem', // 16px
    '3xl': '1.375rem', // 22px
    '4xl': '2rem', // 32px
    '5xl': '3.25rem', // 52px
    '6xl': '6.625rem', // 106px
    mdrange: 'clamp(1rem, -1.5rem + 8vw, 3rem)'
}

const fontWeights = {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
}

const lineHeights = {
    under: 0.96, // 96%
    shorter: 1.2, // 120%
    short: 1.4 // 140%
}

export {fontSizes, fontWeights, lineHeights}
