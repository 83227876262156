import React from 'react'
import PropTypes from 'prop-types'
import logger from '@salesforce/retail-react-app/app/utils/logger-instance'

const LOCALE_MESSAGES = {
    'de-DE': 'Deutsch',
    'en-GB': 'English',
    'en-US': 'English',
    'es-ES': 'Español',
    'es-US': 'Español',
    'fr-FR': 'Français',
    'it-IT': 'Italianio'
}

/**
 * LocaleText component displays a localized message based on the provided shortCode.
 *
 * This component looks up a message from a predefined set of locale messages.
 * If the shortCode is not found, it logs an error and displays a default message.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.shortCode - The locale short code used to retrieve the message.
 * @returns {JSX.Element} The localized message or a default message if not found.
 */

const LocaleText = ({shortCode}) => {
    const message = LOCALE_MESSAGES[shortCode]

    if (!message) {
        logger.error(
            `No locale message found for "${shortCode}". Please update the list accordingly.`,
            {
                namespace: ' LocaleText',
                additionalProperties: {shortCode}
            }
        )
        return <>Unknown {shortCode}</>
    }

    return <>{message}</>
}

LocaleText.displayName = 'LocaleText'

LocaleText.propTypes = {
    shortCode: PropTypes.string.isRequired
}

export default LocaleText
