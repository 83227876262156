import React from 'react'
import PropTypes from 'prop-types'
import {icon} from '@salesforce/retail-react-app/app/components/icons'

// 256 from 270 flags from https://github.com/lipis/flag-icons: 4x3 format

// Skip flags are not in COUNTRY_CODES:
// "ARAB": "League of Arab States",
// "CEFTA": "Central European Free Trade Agreement"
// "EAC": "East African Community"
// "ES_CT": "Catalonia"
// "ES_GA": "Galicia"
// "ES_PV": "Basque Country"
// "GB_ENG": "England"
// "GB_NIR": "Northern Ireland"
// "GB_SCT": "Scotland"
// "GB_WLS": "Wales"
// "PC": "Pacific Community"
// "SH_HL": "Saint Helena"
// "SH_TA": "Tristan da Cunha"
// "UN": "United Nations"
// Exception is "XX": "Unknown" to keep for unknown case

// For information: COUNTRY_CODES are not in flags:
// "AN": 'Netherlands Antilles'
// "TA": 'Tristan da Cunha'

// How to add the SVG on Composable Storefront __SVG_SPRITE_NODE__ sprite:
// 1. Add the SVG in overrides/app/assets/svg.
// 2. Import the SVG.
// 3. When importing the SVG, it will be extracted to a single sprite sheet by the svg-sprite-loader webpack plugin
//    at build time and injected in the <body> tag during SSR.

// Import SVG flags files
import flagAc from '../../assets/svg/flags/flag-ac.svg'
import flagAd from '../../assets/svg/flags/flag-ad.svg'
import flagAe from '../../assets/svg/flags/flag-ae.svg'
import flagAf from '../../assets/svg/flags/flag-af.svg'
import flagAg from '../../assets/svg/flags/flag-ag.svg'
import flagAi from '../../assets/svg/flags/flag-ai.svg'
import flagAl from '../../assets/svg/flags/flag-al.svg'
import flagAm from '../../assets/svg/flags/flag-am.svg'
import flagAn from '../../assets/svg/flags/flag-an.svg'
import flagAo from '../../assets/svg/flags/flag-ao.svg'
import flagAq from '../../assets/svg/flags/flag-aq.svg'
import flagAr from '../../assets/svg/flags/flag-ar.svg'
import flagAs from '../../assets/svg/flags/flag-as.svg'
import flagAt from '../../assets/svg/flags/flag-at.svg'
import flagAu from '../../assets/svg/flags/flag-au.svg'
import flagAw from '../../assets/svg/flags/flag-aw.svg'
import flagAx from '../../assets/svg/flags/flag-ax.svg'
import flagAz from '../../assets/svg/flags/flag-az.svg'
import flagBa from '../../assets/svg/flags/flag-ba.svg'
import flagBb from '../../assets/svg/flags/flag-bb.svg'
import flagBd from '../../assets/svg/flags/flag-bd.svg'
import flagBe from '../../assets/svg/flags/flag-be.svg'
import flagBf from '../../assets/svg/flags/flag-bf.svg'
import flagBg from '../../assets/svg/flags/flag-bg.svg'
import flagBh from '../../assets/svg/flags/flag-bh.svg'
import flagBi from '../../assets/svg/flags/flag-bi.svg'
import flagBj from '../../assets/svg/flags/flag-bj.svg'
import flagBl from '../../assets/svg/flags/flag-bl.svg'
import flagBm from '../../assets/svg/flags/flag-bm.svg'
import flagBn from '../../assets/svg/flags/flag-bn.svg'
import flagBo from '../../assets/svg/flags/flag-bo.svg'
import flagBq from '../../assets/svg/flags/flag-bq.svg'
import flagBr from '../../assets/svg/flags/flag-br.svg'
import flagBs from '../../assets/svg/flags/flag-bs.svg'
import flagBt from '../../assets/svg/flags/flag-bt.svg'
import flagBv from '../../assets/svg/flags/flag-bv.svg'
import flagBw from '../../assets/svg/flags/flag-bw.svg'
import flagBy from '../../assets/svg/flags/flag-by.svg'
import flagBz from '../../assets/svg/flags/flag-bz.svg'
import flagCa from '../../assets/svg/flags/flag-ca.svg'
import flagCc from '../../assets/svg/flags/flag-cc.svg'
import flagCd from '../../assets/svg/flags/flag-cd.svg'
import flagCf from '../../assets/svg/flags/flag-cf.svg'
import flagCg from '../../assets/svg/flags/flag-cg.svg'
import flagCh from '../../assets/svg/flags/flag-ch.svg'
import flagCi from '../../assets/svg/flags/flag-ci.svg'
import flagCk from '../../assets/svg/flags/flag-ck.svg'
import flagCl from '../../assets/svg/flags/flag-cl.svg'
import flagCm from '../../assets/svg/flags/flag-cm.svg'
import flagCn from '../../assets/svg/flags/flag-cn.svg'
import flagCo from '../../assets/svg/flags/flag-co.svg'
import flagCp from '../../assets/svg/flags/flag-cp.svg'
import flagCr from '../../assets/svg/flags/flag-cr.svg'
import flagCu from '../../assets/svg/flags/flag-cu.svg'
import flagCv from '../../assets/svg/flags/flag-cv.svg'
import flagCw from '../../assets/svg/flags/flag-cw.svg'
import flagCx from '../../assets/svg/flags/flag-cx.svg'
import flagCy from '../../assets/svg/flags/flag-cy.svg'
import flagCz from '../../assets/svg/flags/flag-cz.svg'
import flagDe from '../../assets/svg/flags/flag-de.svg'
import flagDg from '../../assets/svg/flags/flag-dg.svg'
import flagDj from '../../assets/svg/flags/flag-dj.svg'
import flagDk from '../../assets/svg/flags/flag-dk.svg'
import flagDm from '../../assets/svg/flags/flag-dm.svg'
import flagDo from '../../assets/svg/flags/flag-do.svg'
import flagDz from '../../assets/svg/flags/flag-dz.svg'
import flagEc from '../../assets/svg/flags/flag-ec.svg'
import flagEe from '../../assets/svg/flags/flag-ee.svg'
import flagEg from '../../assets/svg/flags/flag-eg.svg'
import flagEh from '../../assets/svg/flags/flag-eh.svg'
import flagEr from '../../assets/svg/flags/flag-er.svg'
import flagEs from '../../assets/svg/flags/flag-es.svg'
import flagEt from '../../assets/svg/flags/flag-et.svg'
import flagEu from '../../assets/svg/flags/flag-eu.svg'
import flagFi from '../../assets/svg/flags/flag-fi.svg'
import flagFj from '../../assets/svg/flags/flag-fj.svg'
import flagFk from '../../assets/svg/flags/flag-fk.svg'
import flagFm from '../../assets/svg/flags/flag-fm.svg'
import flagFo from '../../assets/svg/flags/flag-fo.svg'
import flagFr from '../../assets/svg/flags/flag-fr.svg'
import flagGa from '../../assets/svg/flags/flag-ga.svg'
import flagGb from '../../assets/svg/flags/flag-gb.svg'
import flagGd from '../../assets/svg/flags/flag-gd.svg'
import flagGe from '../../assets/svg/flags/flag-ge.svg'
import flagGf from '../../assets/svg/flags/flag-gf.svg'
import flagGg from '../../assets/svg/flags/flag-gg.svg'
import flagGh from '../../assets/svg/flags/flag-gh.svg'
import flagGi from '../../assets/svg/flags/flag-gi.svg'
import flagGl from '../../assets/svg/flags/flag-gl.svg'
import flagGm from '../../assets/svg/flags/flag-gm.svg'
import flagGn from '../../assets/svg/flags/flag-gn.svg'
import flagGp from '../../assets/svg/flags/flag-gp.svg'
import flagGq from '../../assets/svg/flags/flag-gq.svg'
import flagGr from '../../assets/svg/flags/flag-gr.svg'
import flagGs from '../../assets/svg/flags/flag-gs.svg'
import flagGt from '../../assets/svg/flags/flag-gt.svg'
import flagGu from '../../assets/svg/flags/flag-gu.svg'
import flagGw from '../../assets/svg/flags/flag-gw.svg'
import flagGy from '../../assets/svg/flags/flag-gy.svg'
import flagHk from '../../assets/svg/flags/flag-hk.svg'
import flagHm from '../../assets/svg/flags/flag-hm.svg'
import flagHn from '../../assets/svg/flags/flag-hn.svg'
import flagHr from '../../assets/svg/flags/flag-hr.svg'
import flagHt from '../../assets/svg/flags/flag-ht.svg'
import flagHu from '../../assets/svg/flags/flag-hu.svg'
import flagId from '../../assets/svg/flags/flag-id.svg'
import flagIe from '../../assets/svg/flags/flag-ie.svg'
import flagIl from '../../assets/svg/flags/flag-il.svg'
import flagIm from '../../assets/svg/flags/flag-im.svg'
import flagIn from '../../assets/svg/flags/flag-in.svg'
import flagIo from '../../assets/svg/flags/flag-io.svg'
import flagIq from '../../assets/svg/flags/flag-iq.svg'
import flagIr from '../../assets/svg/flags/flag-ir.svg'
import flagIs from '../../assets/svg/flags/flag-is.svg'
import flagIt from '../../assets/svg/flags/flag-it.svg'
import flagJe from '../../assets/svg/flags/flag-je.svg'
import flagJm from '../../assets/svg/flags/flag-jm.svg'
import flagJo from '../../assets/svg/flags/flag-jo.svg'
import flagJp from '../../assets/svg/flags/flag-jp.svg'
import flagKe from '../../assets/svg/flags/flag-ke.svg'
import flagKg from '../../assets/svg/flags/flag-kg.svg'
import flagKh from '../../assets/svg/flags/flag-kh.svg'
import flagKi from '../../assets/svg/flags/flag-ki.svg'
import flagKm from '../../assets/svg/flags/flag-km.svg'
import flagKn from '../../assets/svg/flags/flag-kn.svg'
import flagKp from '../../assets/svg/flags/flag-kp.svg'
import flagKr from '../../assets/svg/flags/flag-kr.svg'
import flagKw from '../../assets/svg/flags/flag-kw.svg'
import flagKy from '../../assets/svg/flags/flag-ky.svg'
import flagKz from '../../assets/svg/flags/flag-kz.svg'
import flagLa from '../../assets/svg/flags/flag-la.svg'
import flagLb from '../../assets/svg/flags/flag-lb.svg'
import flagLc from '../../assets/svg/flags/flag-lc.svg'
import flagLi from '../../assets/svg/flags/flag-li.svg'
import flagLk from '../../assets/svg/flags/flag-lk.svg'
import flagLr from '../../assets/svg/flags/flag-lr.svg'
import flagLs from '../../assets/svg/flags/flag-ls.svg'
import flagLt from '../../assets/svg/flags/flag-lt.svg'
import flagLu from '../../assets/svg/flags/flag-lu.svg'
import flagLv from '../../assets/svg/flags/flag-lv.svg'
import flagLy from '../../assets/svg/flags/flag-ly.svg'
import flagMa from '../../assets/svg/flags/flag-ma.svg'
import flagMc from '../../assets/svg/flags/flag-mc.svg'
import flagMd from '../../assets/svg/flags/flag-md.svg'
import flagMe from '../../assets/svg/flags/flag-me.svg'
import flagMf from '../../assets/svg/flags/flag-mf.svg'
import flagMg from '../../assets/svg/flags/flag-mg.svg'
import flagMh from '../../assets/svg/flags/flag-mh.svg'
import flagMk from '../../assets/svg/flags/flag-mk.svg'
import flagMl from '../../assets/svg/flags/flag-ml.svg'
import flagMm from '../../assets/svg/flags/flag-mm.svg'
import flagMn from '../../assets/svg/flags/flag-mn.svg'
import flagMo from '../../assets/svg/flags/flag-mo.svg'
import flagMp from '../../assets/svg/flags/flag-mp.svg'
import flagMq from '../../assets/svg/flags/flag-mq.svg'
import flagMr from '../../assets/svg/flags/flag-mr.svg'
import flagMs from '../../assets/svg/flags/flag-ms.svg'
import flagMt from '../../assets/svg/flags/flag-mt.svg'
import flagMu from '../../assets/svg/flags/flag-mu.svg'
import flagMv from '../../assets/svg/flags/flag-mv.svg'
import flagMw from '../../assets/svg/flags/flag-mw.svg'
import flagMx from '../../assets/svg/flags/flag-mx.svg'
import flagMy from '../../assets/svg/flags/flag-my.svg'
import flagMz from '../../assets/svg/flags/flag-mz.svg'
import flagNa from '../../assets/svg/flags/flag-na.svg'
import flagNc from '../../assets/svg/flags/flag-nc.svg'
import flagNe from '../../assets/svg/flags/flag-ne.svg'
import flagNf from '../../assets/svg/flags/flag-nf.svg'
import flagNg from '../../assets/svg/flags/flag-ng.svg'
import flagNi from '../../assets/svg/flags/flag-ni.svg'
import flagNl from '../../assets/svg/flags/flag-nl.svg'
import flagNo from '../../assets/svg/flags/flag-no.svg'
import flagNp from '../../assets/svg/flags/flag-np.svg'
import flagNr from '../../assets/svg/flags/flag-nr.svg'
import flagNu from '../../assets/svg/flags/flag-nu.svg'
import flagNz from '../../assets/svg/flags/flag-nz.svg'
import flagOm from '../../assets/svg/flags/flag-om.svg'
import flagPa from '../../assets/svg/flags/flag-pa.svg'
import flagPe from '../../assets/svg/flags/flag-pe.svg'
import flagPf from '../../assets/svg/flags/flag-pf.svg'
import flagPg from '../../assets/svg/flags/flag-pg.svg'
import flagPh from '../../assets/svg/flags/flag-ph.svg'
import flagPk from '../../assets/svg/flags/flag-pk.svg'
import flagPl from '../../assets/svg/flags/flag-pl.svg'
import flagPm from '../../assets/svg/flags/flag-pm.svg'
import flagPn from '../../assets/svg/flags/flag-pn.svg'
import flagPr from '../../assets/svg/flags/flag-pr.svg'
import flagPs from '../../assets/svg/flags/flag-ps.svg'
import flagPt from '../../assets/svg/flags/flag-pt.svg'
import flagPw from '../../assets/svg/flags/flag-pw.svg'
import flagPy from '../../assets/svg/flags/flag-py.svg'
import flagQa from '../../assets/svg/flags/flag-qa.svg'
import flagRe from '../../assets/svg/flags/flag-re.svg'
import flagRo from '../../assets/svg/flags/flag-ro.svg'
import flagRs from '../../assets/svg/flags/flag-rs.svg'
import flagRu from '../../assets/svg/flags/flag-ru.svg'
import flagRw from '../../assets/svg/flags/flag-rw.svg'
import flagSa from '../../assets/svg/flags/flag-sa.svg'
import flagSb from '../../assets/svg/flags/flag-sb.svg'
import flagSc from '../../assets/svg/flags/flag-sc.svg'
import flagSd from '../../assets/svg/flags/flag-sd.svg'
import flagSe from '../../assets/svg/flags/flag-se.svg'
import flagSg from '../../assets/svg/flags/flag-sg.svg'
import flagSh from '../../assets/svg/flags/flag-sh.svg'
import flagSi from '../../assets/svg/flags/flag-si.svg'
import flagSj from '../../assets/svg/flags/flag-sj.svg'
import flagSk from '../../assets/svg/flags/flag-sk.svg'
import flagSl from '../../assets/svg/flags/flag-sl.svg'
import flagSm from '../../assets/svg/flags/flag-sm.svg'
import flagSn from '../../assets/svg/flags/flag-sn.svg'
import flagSo from '../../assets/svg/flags/flag-so.svg'
import flagSr from '../../assets/svg/flags/flag-sr.svg'
import flagSs from '../../assets/svg/flags/flag-ss.svg'
import flagSt from '../../assets/svg/flags/flag-st.svg'
import flagSv from '../../assets/svg/flags/flag-sv.svg'
import flagSx from '../../assets/svg/flags/flag-sx.svg'
import flagSy from '../../assets/svg/flags/flag-sy.svg'
import flagSz from '../../assets/svg/flags/flag-sz.svg'
import flagTa from '../../assets/svg/flags/flag-ta.svg'
import flagTc from '../../assets/svg/flags/flag-tc.svg'
import flagTd from '../../assets/svg/flags/flag-td.svg'
import flagTf from '../../assets/svg/flags/flag-tf.svg'
import flagTg from '../../assets/svg/flags/flag-tg.svg'
import flagTh from '../../assets/svg/flags/flag-th.svg'
import flagTj from '../../assets/svg/flags/flag-tj.svg'
import flagTk from '../../assets/svg/flags/flag-tk.svg'
import flagTl from '../../assets/svg/flags/flag-tl.svg'
import flagTm from '../../assets/svg/flags/flag-tm.svg'
import flagTn from '../../assets/svg/flags/flag-tn.svg'
import flagTo from '../../assets/svg/flags/flag-to.svg'
import flagTr from '../../assets/svg/flags/flag-tr.svg'
import flagTt from '../../assets/svg/flags/flag-tt.svg'
import flagTv from '../../assets/svg/flags/flag-tv.svg'
import flagTw from '../../assets/svg/flags/flag-tw.svg'
import flagTz from '../../assets/svg/flags/flag-tz.svg'
import flagUa from '../../assets/svg/flags/flag-ua.svg'
import flagUg from '../../assets/svg/flags/flag-ug.svg'
import flagUm from '../../assets/svg/flags/flag-um.svg'
import flagUs from '../../assets/svg/flags/flag-us.svg'
import flagUy from '../../assets/svg/flags/flag-uy.svg'
import flagUz from '../../assets/svg/flags/flag-uz.svg'
import flagVa from '../../assets/svg/flags/flag-va.svg'
import flagVc from '../../assets/svg/flags/flag-vc.svg'
import flagVe from '../../assets/svg/flags/flag-ve.svg'
import flagVg from '../../assets/svg/flags/flag-vg.svg'
import flagVi from '../../assets/svg/flags/flag-vi.svg'
import flagVn from '../../assets/svg/flags/flag-vn.svg'
import flagVu from '../../assets/svg/flags/flag-vu.svg'
import flagWf from '../../assets/svg/flags/flag-wf.svg'
import flagWs from '../../assets/svg/flags/flag-ws.svg'
import flagXk from '../../assets/svg/flags/flag-xk.svg'
import flagXx from '../../assets/svg/flags/flag-xx.svg'
import flagYe from '../../assets/svg/flags/flag-ye.svg'
import flagYt from '../../assets/svg/flags/flag-yt.svg'
import flagZa from '../../assets/svg/flags/flag-za.svg'
import flagZm from '../../assets/svg/flags/flag-zm.svg'
import flagZw from '../../assets/svg/flags/flag-zw.svg'

// Organize SVG imports in a flags object
const flags = {
    AC: flagAc,
    AD: flagAd,
    AE: flagAe,
    AF: flagAf,
    AG: flagAg,
    AI: flagAi,
    AL: flagAl,
    AM: flagAm,
    AN: flagAn,
    AO: flagAo,
    AQ: flagAq,
    AR: flagAr,
    AS: flagAs,
    AT: flagAt,
    AU: flagAu,
    AW: flagAw,
    AX: flagAx,
    AZ: flagAz,
    BA: flagBa,
    BB: flagBb,
    BD: flagBd,
    BE: flagBe,
    BF: flagBf,
    BG: flagBg,
    BH: flagBh,
    BI: flagBi,
    BJ: flagBj,
    BL: flagBl,
    BM: flagBm,
    BN: flagBn,
    BO: flagBo,
    BQ: flagBq,
    BR: flagBr,
    BS: flagBs,
    BT: flagBt,
    BV: flagBv,
    BW: flagBw,
    BY: flagBy,
    BZ: flagBz,
    CA: flagCa,
    CC: flagCc,
    CD: flagCd,
    CF: flagCf,
    CG: flagCg,
    CH: flagCh,
    CI: flagCi,
    CK: flagCk,
    CL: flagCl,
    CM: flagCm,
    CN: flagCn,
    CO: flagCo,
    CP: flagCp,
    CR: flagCr,
    CU: flagCu,
    CV: flagCv,
    CW: flagCw,
    CX: flagCx,
    CY: flagCy,
    CZ: flagCz,
    DE: flagDe,
    DG: flagDg,
    DJ: flagDj,
    DK: flagDk,
    DM: flagDm,
    DO: flagDo,
    DZ: flagDz,
    EC: flagEc,
    EE: flagEe,
    EG: flagEg,
    EH: flagEh,
    ER: flagEr,
    ES: flagEs,
    ET: flagEt,
    EU: flagEu,
    FI: flagFi,
    FJ: flagFj,
    FK: flagFk,
    FM: flagFm,
    FO: flagFo,
    FR: flagFr,
    GA: flagGa,
    GB: flagGb,
    GD: flagGd,
    GE: flagGe,
    GF: flagGf,
    GG: flagGg,
    GH: flagGh,
    GI: flagGi,
    GL: flagGl,
    GM: flagGm,
    GN: flagGn,
    GP: flagGp,
    GQ: flagGq,
    GR: flagGr,
    GS: flagGs,
    GT: flagGt,
    GU: flagGu,
    GW: flagGw,
    GY: flagGy,
    HK: flagHk,
    HM: flagHm,
    HN: flagHn,
    HR: flagHr,
    HT: flagHt,
    HU: flagHu,
    ID: flagId,
    IE: flagIe,
    IL: flagIl,
    IM: flagIm,
    IN: flagIn,
    IO: flagIo,
    IQ: flagIq,
    IR: flagIr,
    IS: flagIs,
    IT: flagIt,
    JE: flagJe,
    JM: flagJm,
    JO: flagJo,
    JP: flagJp,
    KE: flagKe,
    KG: flagKg,
    KH: flagKh,
    KI: flagKi,
    KM: flagKm,
    KN: flagKn,
    KP: flagKp,
    KR: flagKr,
    KW: flagKw,
    KY: flagKy,
    KZ: flagKz,
    LA: flagLa,
    LB: flagLb,
    LC: flagLc,
    LI: flagLi,
    LK: flagLk,
    LR: flagLr,
    LS: flagLs,
    LT: flagLt,
    LU: flagLu,
    LV: flagLv,
    LY: flagLy,
    MA: flagMa,
    MC: flagMc,
    MD: flagMd,
    ME: flagMe,
    MF: flagMf,
    MG: flagMg,
    MH: flagMh,
    MK: flagMk,
    ML: flagMl,
    MM: flagMm,
    MN: flagMn,
    MO: flagMo,
    MP: flagMp,
    MQ: flagMq,
    MR: flagMr,
    MS: flagMs,
    MT: flagMt,
    MU: flagMu,
    MV: flagMv,
    MW: flagMw,
    MX: flagMx,
    MY: flagMy,
    MZ: flagMz,
    NA: flagNa,
    NC: flagNc,
    NE: flagNe,
    NF: flagNf,
    NG: flagNg,
    NI: flagNi,
    NL: flagNl,
    NO: flagNo,
    NP: flagNp,
    NR: flagNr,
    NU: flagNu,
    NZ: flagNz,
    OM: flagOm,
    PA: flagPa,
    PE: flagPe,
    PF: flagPf,
    PG: flagPg,
    PH: flagPh,
    PK: flagPk,
    PL: flagPl,
    PM: flagPm,
    PN: flagPn,
    PR: flagPr,
    PS: flagPs,
    PT: flagPt,
    PW: flagPw,
    PY: flagPy,
    QA: flagQa,
    RE: flagRe,
    RO: flagRo,
    RS: flagRs,
    RU: flagRu,
    RW: flagRw,
    SA: flagSa,
    SB: flagSb,
    SC: flagSc,
    SD: flagSd,
    SE: flagSe,
    SG: flagSg,
    SH: flagSh,
    SI: flagSi,
    SJ: flagSj,
    SK: flagSk,
    SL: flagSl,
    SM: flagSm,
    SN: flagSn,
    SO: flagSo,
    SR: flagSr,
    SS: flagSs,
    ST: flagSt,
    SV: flagSv,
    SX: flagSx,
    SY: flagSy,
    SZ: flagSz,
    TA: flagTa,
    TC: flagTc,
    TD: flagTd,
    TF: flagTf,
    TG: flagTg,
    TH: flagTh,
    TJ: flagTj,
    TK: flagTk,
    TL: flagTl,
    TM: flagTm,
    TN: flagTn,
    TO: flagTo,
    TR: flagTr,
    TT: flagTt,
    TV: flagTv,
    TW: flagTw,
    TZ: flagTz,
    UA: flagUa,
    UG: flagUg,
    UM: flagUm,
    US: flagUs,
    UY: flagUy,
    UZ: flagUz,
    VA: flagVa,
    VC: flagVc,
    VE: flagVe,
    VG: flagVg,
    VI: flagVi,
    VN: flagVn,
    VU: flagVu,
    WF: flagWf,
    WS: flagWs,
    XK: flagXk,
    XX: flagXx,
    YE: flagYe,
    YT: flagYt,
    ZA: flagZa,
    ZM: flagZm,
    ZW: flagZw
}

// Generate flag icons using the imported SVGs
const generateFlagIcons = () => {
    return Object.keys(flags).reduce((icons, key) => {
        icons[key] = icon(`flag-${key.toLowerCase()}`)
        return icons
    }, {})
}

const flagIcons = generateFlagIcons()

const FlagIcon = ({countryCode, ...props}) => {
    const IconComponent = flagIcons[countryCode?.toUpperCase()] || flagIcons['XX']

    return <IconComponent {...props} />
}

FlagIcon.propTypes = {
    countryCode: PropTypes.string.isRequired
}

export default FlagIcon
