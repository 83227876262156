export default {
    baseStyle: {
        container: {
            borderWidth: 1,
            p: 16,
            width: 'full',
            gap: 12
        },
        icon: {
            flexShrink: 0,
            boxSize: 4
        },
        description: {
            lineHeight: 'short',
            textStyle: 'bodyLarge2'
        }
    },
    variants: {
        info: {
            container: {
                borderColor: 'white',
                backgroundColor: 'blue',
                color: 'white'
            },
            icon: {
                color: 'white',
                insideColor: 'blue'
            }
        },
        success: {
            container: {
                borderColor: 'green',
                backgroundColor: 'lightGreen',
                color: 'fullBlack'
            },
            icon: {
                color: 'green'
            }
        },
        warning: {
            container: {
                borderColor: 'orange',
                backgroundColor: 'lightPink',
                color: 'fullBlack'
            },
            icon: {
                color: 'orange'
            }
        },
        error: {
            container: {
                borderColor: 'red',
                backgroundColor: 'lightPink',
                color: 'fullBlack'
            },
            icon: {
                color: 'red'
            }
        },
        defaultProps: {
            variant: 'info'
        }
    }
}
