const colors = {
    fullBlack: '#000000',
    charcoalGray: '#4F4F4F',
    darkGray: '#5E5E5E',
    dimGray: '#757575',
    shadyGray: '#828282',
    gray: '#A6A6A6',
    borderGray: '#D9D9D9',
    whiteSmoke: '#F5F5F7',
    white: '#ffffff',
    blue: '#143A64',
    navyBlue: '#112A46',
    lightBlue: '#A7CAD9',
    ohra: '#BE8E52',
    beige: '#E3D3C1',
    lightBeige: '#FFFAF6',
    burgundy: '#81281C',
    yellow: '#FFD14E',
    pink: '#E3C9CF',
    lightPink: '#FDDDDD',
    orange: '#FE7502',
    red: '#F22F2F',
    green: '#32592D',
    lightGreen: '#D2DFC8'
}

export default colors
