import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

// Components
import {
    Box,
    Flex,
    IconButton,
    Link,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {ChevronLeftIcon} from '../custom-icons'

/**
 * Drawer menu header of level 2 panel on mobile
 *
 * @param {string} name - Name of the category
 * @param {string} viewAllLink - Link to view all products in the category
 * @param {function} close - Function to close the drawer
 * @param {object} backLinkRef - Ref to the back button
 *
 * @returns {ReactElement}
 */
const DrawerMenuLvl2MobHeader = ({name, viewAllLink, close, backLinkRef}) => {
    const intl = useIntl()

    return (
        <Flex
            align="center"
            height="57px"
            pr="10px"
            borderBottomWidth={1}
            borderColor={'borderGray'}
        >
            <Box flex={1} marginRight="auto" textAlign="left">
                <IconButton
                    aria-label={intl.formatMessage({
                        id: 'drawer_menu.link.back',
                        defaultMessage: 'Back to previous menu'
                    })}
                    icon={<ChevronLeftIcon boxSize={3} />}
                    onClick={close}
                    size="md"
                    ref={backLinkRef}
                />
            </Box>
            <Text
                role="heading"
                aria-level="2"
                fontFamily={'heading'}
                fontSize="2xl"
                flex={1}
                textAlign={'center'}
                textTransform={'uppercase'}
            >
                {name}
            </Text>
            <Box flex={1} marginLeft="auto" textAlign="right">
                {viewAllLink && (
                    <Link as={RouteLink} to={viewAllLink} color="black" textAlign="right">
                        {intl.formatMessage(
                            {
                                id: 'drawer_menu.link.shop_all',
                                defaultMessage: 'View all {name}'
                            },
                            {
                                name: name?.toLowerCase() || ''
                            }
                        )}
                    </Link>
                )}
            </Box>
        </Flex>
    )
}

DrawerMenuLvl2MobHeader.propTypes = {
    name: PropTypes.string,
    viewAllLink: PropTypes.string,
    close: PropTypes.func,
    backLinkRef: PropTypes.object
}

export default DrawerMenuLvl2MobHeader
