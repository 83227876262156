import {IntlContext} from 'react-intl'
import {useContext} from 'react'

export const useFormatMessage = (intlDefineMessage, defaultMessage = '') => {
    const intl = useContext(IntlContext)

    // Check if `intl` is available to format messages.
    // This is important because `intl` might not be available in certain contexts,
    // such as when the component is used in a Toast manager portal without an `IntlProvider`.
    return intl ? intl.formatMessage(intlDefineMessage) : defaultMessage
}
