export default {
    baseStyle: {
        color: 'darkGray',
        _disabled: {
            opacity: '1',
            color: 'fullBlack'
        },
        _invalid: {
            color: 'red'
        }
    },
    variants: {
        unchanged: {
            color: 'darkGray',
            _disabled: {
                color: 'darkGray'
            }
        }
    }
}
