import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/**
 * Custom hook to determine the type of the current site based on its ID.
 * Utilizes the `useMultiSite` hook to get the current site information.
 *
 * @returns {Object} An object containing boolean values indicating the type of the site:
 * - `isEU`: true if the site ID includes 'EU'
 * - `isUS`: true if the site ID includes 'US'
 * - `isOutlet`: true if the site ID includes 'OUTLET'
 */
const useSiteType = () => {
    const {site} = useMultiSite()

    const isSiteType = (siteId, type) => {
        return siteId.includes(type)
    }

    return {
        isEU: isSiteType(site.id, 'EU'),
        isUS: isSiteType(site.id, 'US'),
        isOutlet: isSiteType(site.id, 'OUTLET')
    }
}

export default useSiteType
