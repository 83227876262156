import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

// Components
import {PDCLink} from '../../page-designer/assets/pdc-link'
import DrawerMenuLocaleSelector from './drawer-menu-locale-selector'
import {Text, Portal} from '@salesforce/retail-react-app/app/components/shared/ui'
import {Menu, MenuList, MenuItem, MenuGroup} from '@chakra-ui/react'

import {categoryUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'

import {PageLink} from './drawer-menu-lvl2-pd-pages'

/**
 * SEO Menu
 * As the Drawer Menu is mounted only when open, we need a hidden menu
 * always rendered in the DOM for SEO reasons
 *
 * @param {Object} props - Component props
 * @param {Object} props.root - Root item
 * @param {String} props.itemsKey - Key of the items in the root object
 * @param {Function|String} props.itemsFilter - Filter function or key to filter items
 * @param {Array} props.firstLevelTriggers - First level triggers
 * @param {Array} props.primaryDirectLinks - Primary direct links
 * @param {Array} props.secondaryDirectLinks - Secondary direct links
 * @returns {React.ReactElement}
 */
const SEOMenu = ({
    root,
    itemsKey,
    itemsFilter,
    firstLevelTriggers,
    primaryDirectLinks,
    secondaryDirectLinks
}) => {
    const intl = useIntl()
    const filter = itemsFilter
        ? (item) => (typeof itemsFilter === 'function' ? itemsFilter(item) : !!item[itemsFilter])
        : null

    return (
        <>
            {/* Replace three first lines by these ones to see the SEO menu on the storefront 
                (the button will appear in the header on the right of the search icon) */}
            {/* <Menu>
            <MenuButton as={Button} colorScheme="pink">
                Profile
            </MenuButton>
            {root?.[itemsKey] && (
                <MenuList height="calc(100vh - 60px)" overflowY={'auto'}> */}
            <Menu isOpen={false}>
                {root?.[itemsKey] && (
                    <Portal>
                        <MenuList height="0" overflow={'hidden'}>
                            {/* Categories */}
                            {root?.[itemsKey].map((itemLvl1) => {
                                const {id, name} = itemLvl1
                                return (
                                    <MenuGroup key={id} title={name}>
                                        <MenuItem as="a" href={categoryUrlBuilder(itemLvl1)}>
                                            {intl.formatMessage(
                                                {
                                                    id: 'drawer_menu.link.shop_all',
                                                    defaultMessage: 'View all {name}'
                                                },
                                                {
                                                    name: name || ''
                                                }
                                            )}
                                        </MenuItem>
                                        {itemLvl1?.[itemsKey] &&
                                            itemLvl1?.[itemsKey].filter(filter).map((itemLvl2) => {
                                                const {id, name} = itemLvl2
                                                return (
                                                    <MenuGroup key={id} title={name}>
                                                        {itemLvl2?.[itemsKey] &&
                                                            itemLvl2?.[itemsKey].map((itemLvl3) => {
                                                                const {id, name} = itemLvl3
                                                                return (
                                                                    <MenuItem
                                                                        key={id}
                                                                        as="a"
                                                                        href={categoryUrlBuilder(
                                                                            itemLvl3
                                                                        )}
                                                                    >
                                                                        {name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        <MenuItem
                                                            as="a"
                                                            href={categoryUrlBuilder(itemLvl2)}
                                                        >
                                                            {intl.formatMessage(
                                                                {
                                                                    id: 'drawer_menu.link.shop_all',
                                                                    defaultMessage:
                                                                        'View all {name}'
                                                                },
                                                                {
                                                                    name: name || ''
                                                                }
                                                            )}
                                                        </MenuItem>
                                                    </MenuGroup>
                                                )
                                            })}
                                    </MenuGroup>
                                )
                            })}
                            {firstLevelTriggers?.map((item) => {
                                const {id} = item
                                const pageIDS = item.regions[0]?.components || []
                                return (
                                    <MenuGroup key={id} title={item?.data?.title}>
                                        {pageIDS.map((pageID) => (
                                            <PageLink
                                                key={pageID?.id}
                                                pageId={pageID?.data?.page}
                                                role="menuitem"
                                                tabIndex="-1"
                                            />
                                        ))}
                                    </MenuGroup>
                                )
                            })}
                            {primaryDirectLinks &&
                                primaryDirectLinks.map((item, i) => {
                                    return (
                                        <PDCLink
                                            key={i}
                                            custom={item?.custom}
                                            label={item?.data?.label}
                                            role="menuitem"
                                            tabIndex="-1"
                                        ></PDCLink>
                                    )
                                })}
                            {secondaryDirectLinks &&
                                secondaryDirectLinks.map((item, i) => {
                                    return (
                                        <PDCLink
                                            key={i}
                                            custom={item?.custom}
                                            label={item?.data?.label}
                                            role="menuitem"
                                            tabIndex="-1"
                                        ></PDCLink>
                                    )
                                })}
                            <MenuItem as="a" href="/account">
                                {intl.formatMessage({
                                    id: 'drawer_menu.button.account_details',
                                    defaultMessage: 'My account'
                                })}
                            </MenuItem>
                            {/* TODO : change URL and label of ship-to country link 
                                It will be implemented during the International Shipping task. */}
                            <MenuItem as="a" href="#">
                                {intl.formatMessage({
                                    id: 'drawer_menu.button.ship_to_country',
                                    defaultMessage: 'Delivery country: '
                                })}{' '}
                                <Text as="span" textDecoration={'underline'}>
                                    {'NL'}
                                </Text>
                            </MenuItem>
                            <DrawerMenuLocaleSelector />
                        </MenuList>
                    </Portal>
                )}
            </Menu>
        </>
    )
}

SEOMenu.propTypes = {
    root: PropTypes.object,
    itemsKey: PropTypes.string,
    itemsFilter: PropTypes.string,
    firstLevelTriggers: PropTypes.array,
    primaryDirectLinks: PropTypes.array,
    secondaryDirectLinks: PropTypes.array
}

export default SEOMenu
