import React from 'react'
import PropTypes from 'prop-types'

import {SimpleGrid} from '@salesforce/retail-react-app/app/components/shared/ui'
import {Component} from '@salesforce/commerce-sdk-react/components'

export const PDCFooterReinsurance = ({regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    return (
        <SimpleGrid columns={{base: 2, lg: 4}} mt={{base: '44px', lg: '34px'}}>
            {components.map((reinsuranceIcon, index) => (
                <Component key={index} component={reinsuranceIcon} />
            ))}
        </SimpleGrid>
    )
}

export default PDCFooterReinsurance

PDCFooterReinsurance.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
