import React from 'react'
import {useIntl} from 'react-intl'

// Components
import LocaleText from '../locale-text'
import {
    Button,
    Text,
    VStack,
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverTrigger,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'

import {CheckIcon, ChevronDownIcon} from '../custom-icons'
import {getPathWithLocale} from '@salesforce/retail-react-app/app/utils/url'

import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'

/**
 * Drawer menu Locale Selector
 * @returns {JSX.Element}
 */
const DrawerMenuLocaleSelector = () => {
    const intl = useIntl()
    const {site, buildUrl} = useMultiSite()
    const styles = useMultiStyleConfig('DrawerMenu')
    const {l10n} = site
    const supportedLocaleIds = l10n?.supportedLocales.map((locale) => locale.id)

    return (
        <Popover placement="top-end">
            <PopoverTrigger>
                <Button
                    textStyle={'bodyBase2'}
                    textTransform={'uppercase'}
                    whiteSpace={'normal'}
                    {...styles.footerLink}
                    variant={'link'}
                    p="16px"
                >
                    <LocaleText shortCode={intl.locale} />
                    <ChevronDownIcon boxSize={3} ml={4} />
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverBody>
                    <VStack>
                        {supportedLocaleIds.map((locale) => (
                            <Button
                                key={locale}
                                onClick={() => {
                                    // Update the `locale` in the URL.
                                    const newUrl = getPathWithLocale(locale, buildUrl, {
                                        disallowParams: ['refine']
                                    })
                                    window.location = newUrl
                                }}
                                textTransform={'uppercase'}
                            >
                                {/* Locale name */}
                                <Text {...styles.optionText}>
                                    <LocaleText shortCode={locale} />
                                </Text>

                                {/* Selection indicator */}
                                {intl.locale === locale && (
                                    <CheckIcon boxSize={4} position="absolute" right="10px" />
                                )}
                            </Button>
                        ))}
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default DrawerMenuLocaleSelector
