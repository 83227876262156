export default {
    baseStyle: {
        container: {},
        header: {
            p: ['5px 3px', null, '14px 6px'],
            boxShadow: 'none',
            borderBottomWidth: ['1px', null, 0],
            borderColor: 'borderGray',
            backgroundColor: 'white',
            maxWidth: ['none', null, '359px']
        },
        body: {
            padding: [10, null, '3px 20px 0 16px'],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: ['flex-start', null, 'space-between'],
            backgroundColor: 'white',
            maxWidth: ['none', null, '359px'],
            marginTop: [0, null, '-3px']
        },
        footer: {
            p: ['12px 10px', null, '15px 16px'],
            maxWidth: ['none', null, '359px']
        },
        lvl2Trigger: {
            fontFamily: 'heading',
            fontSize: '22px',
            textTransform: 'uppercase',
            textDecoration: 'none',
            w: 'full',
            display: 'flex',
            justifyContent: ['space-between', null, 'flex-start'],
            py: ['12px', null, 0],
            _hover: {textDecoration: 'none'}
        },
        lvl2DesktopContent: {
            bg: 'white',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '360px',
            bottom: '0',
            overflowY: 'auto',
            borderLeftWidth: ['0', null, '1px'],
            borderColor: 'borderGray'
        },
        primaryDirectLink: {
            textDecoration: 'none',
            textTransform: 'uppercase',
            display: 'block',
            py: ['15px', null, '5px']
        },
        secondaryDirectLink: {
            fontWeight: 400,
            textDecoration: 'none',
            display: 'block',
            py: ['15px', null, '3px'],
            color: 'darkGray'
        },
        footerLink: {
            fontWeight: 400,
            textDecoration: 'none',
            p: '16px',
            textAlign: 'center'
        },
        footerDivider: {
            color: 'gray',
            height: '13px'
        },
        socialsContainer: {
            flex: 1,
            justifyContent: 'flex-start'
        },
        icon: {
            color: 'gray.900',
            width: 5,
            height: 5
        },
        logo: {
            width: 12,
            height: 8
        },
        socialsItem: {
            textAlign: 'center',
            paddingLeft: 2,
            paddingRight: 2
        },
        actions: {
            paddingLeft: 4,
            paddingRight: 4
        },
        actionsItem: {
            paddingTop: 3,
            paddingBottom: 3
        },
        localeSelector: {
            paddingTop: 1,
            paddingBottom: 1
        },
        signout: {
            width: '100%',
            paddingLeft: 8,
            paddingTop: 2,
            paddingBottom: 2,
            _focus: {
                borderRadius: 0,
                boxShadow: 'outline'
            }
        },
        signoutText: {
            marginLeft: 2,
            fontSize: 14,
            fontWeight: 'normal'
        }
    },
    parts: [
        'actions',
        'actionsItem',
        'container',
        'footerDivider',
        'footerLink',
        'icon',
        'localeSelector',
        'primaryDirectLink',
        'secondaryDirectLink',
        'socials',
        'socialsItem',
        'signout'
    ]
}
