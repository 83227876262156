import React from 'react'
import PropTypes from 'prop-types'
import {Box, Heading, AccordionItem, AccordionButton, AccordionPanel} from '@chakra-ui/react'
import {MinusIcon, PlusIcon} from '../../../components/custom-icons'

/**
 * PDC Inner Accordion
 * Displays item of an Accordion : it must have an Accordion as a parent
 *
 * @typedef {Object} PDCInnerAccordionProps
 * @property {string} title - Title of the accordion's item
 * @property {string} desc - Content of the panel of the accordion's item
 * @returns {React.ReactElement} - PDCInnerAccordion component.
 */
export const PDCInnerAccordion = ({title, desc}) => {
    return (
        <AccordionItem mb="-1">
            {({isExpanded}) => (
                <>
                    <Heading as="h3">
                        <AccordionButton>
                            <Box flex="1" textAlign="left">
                                {title}
                            </Box>
                            {isExpanded ? (
                                <MinusIcon boxSize="12px" />
                            ) : (
                                <PlusIcon boxSize="12px" />
                            )}
                        </AccordionButton>
                    </Heading>
                    <AccordionPanel
                        as="section"
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                    />
                </>
            )}
        </AccordionItem>
    )
}

PDCInnerAccordion.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string
}
