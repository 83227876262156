import {defineMessage} from 'react-intl'

// 260 countries
export const COUNTRY_TRANSLATIONS = {
    AC: defineMessage({
        defaultMessage: 'Ascension Island',
        id: 'country.ascension_island'
    }),
    AD: defineMessage({
        defaultMessage: 'Andorra',
        id: 'country.andorra'
    }),
    AE: defineMessage({
        defaultMessage: 'United Arab Emirates',
        id: 'country.united_arab_emirates'
    }),
    AF: defineMessage({
        defaultMessage: 'Afghanistan',
        id: 'country.afghanistan'
    }),
    AG: defineMessage({
        defaultMessage: 'Antigua and Barbuda',
        id: 'country.antigua_and_barbuda'
    }),
    AI: defineMessage({
        defaultMessage: 'Anguilla',
        id: 'country.anguilla'
    }),
    AL: defineMessage({
        defaultMessage: 'Albania',
        id: 'country.albania'
    }),
    AM: defineMessage({
        defaultMessage: 'Armenia',
        id: 'country.armenia'
    }),
    AN: defineMessage({
        defaultMessage: 'Netherlands Antilles',
        id: 'country.netherlands_antilles'
    }),
    AO: defineMessage({
        defaultMessage: 'Angola',
        id: 'country.angola'
    }),
    AQ: defineMessage({
        defaultMessage: 'Antarctica',
        id: 'country.antarctica'
    }),
    AR: defineMessage({
        defaultMessage: 'Argentina',
        id: 'country.argentina'
    }),
    AS: defineMessage({
        defaultMessage: 'American Samoa',
        id: 'country.american_samoa'
    }),
    AT: defineMessage({
        defaultMessage: 'Austria',
        id: 'country.austria'
    }),
    AU: defineMessage({
        defaultMessage: 'Australia',
        id: 'country.australia'
    }),
    AW: defineMessage({
        defaultMessage: 'Aruba',
        id: 'country.aruba'
    }),
    AX: defineMessage({
        defaultMessage: 'Aland Islands',
        id: 'country.aland_islands'
    }),
    AZ: defineMessage({
        defaultMessage: 'Azerbaijan',
        id: 'country.azerbaijan'
    }),
    BA: defineMessage({
        defaultMessage: 'Bosnia and Herzegovina',
        id: 'country.bosnia_and_herzegovina'
    }),
    BB: defineMessage({
        defaultMessage: 'Barbados',
        id: 'country.barbados'
    }),
    BD: defineMessage({
        defaultMessage: 'Bangladesh',
        id: 'country.bangladesh'
    }),
    BE: defineMessage({
        defaultMessage: 'Belgium',
        id: 'country.belgium'
    }),
    BF: defineMessage({
        defaultMessage: 'Burkina Faso',
        id: 'country.burkina_faso'
    }),
    BG: defineMessage({
        defaultMessage: 'Bulgaria',
        id: 'country.bulgaria'
    }),
    BH: defineMessage({
        defaultMessage: 'Bahrain',
        id: 'country.bahrain'
    }),
    BI: defineMessage({
        defaultMessage: 'Burundi',
        id: 'country.burundi'
    }),
    BJ: defineMessage({
        defaultMessage: 'Benin',
        id: 'country.benin'
    }),
    BL: defineMessage({
        defaultMessage: 'Saint Barthelemy',
        id: 'country.saint_barthelemy'
    }),
    BM: defineMessage({
        defaultMessage: 'Bermuda',
        id: 'country.bermuda'
    }),
    BN: defineMessage({
        defaultMessage: 'Brunei',
        id: 'country.brunei'
    }),
    BO: defineMessage({
        defaultMessage: 'Bolivia',
        id: 'country.bolivia'
    }),
    BQ: defineMessage({
        defaultMessage: 'Bonaire, Sint Eustatius, and Saba',
        id: 'country.bonaire_sint_eustatius_and_saba'
    }),
    BR: defineMessage({
        defaultMessage: 'Brazil',
        id: 'country.brazil'
    }),
    BS: defineMessage({
        defaultMessage: 'Bahamas',
        id: 'country.bahamas'
    }),
    BT: defineMessage({
        defaultMessage: 'Bhutan',
        id: 'country.bhutan'
    }),
    BV: defineMessage({
        defaultMessage: 'Bouvet Island',
        id: 'country.bouvet_island'
    }),
    BW: defineMessage({
        defaultMessage: 'Botswana',
        id: 'country.botswana'
    }),
    BY: defineMessage({
        defaultMessage: 'Belarus',
        id: 'country.belarus'
    }),
    BZ: defineMessage({
        defaultMessage: 'Belize',
        id: 'country.belize'
    }),
    CA: defineMessage({
        defaultMessage: 'Canada',
        id: 'country.canada'
    }),
    CC: defineMessage({
        defaultMessage: 'Cocos [Keeling] Islands',
        id: 'country.cocos_keeling_islands'
    }),
    CD: defineMessage({
        defaultMessage: 'Congo - Kinshasa',
        id: 'country.congo_kinshasa'
    }),
    CF: defineMessage({
        defaultMessage: 'Central African Republic',
        id: 'country.central_african_republic'
    }),
    CG: defineMessage({
        defaultMessage: 'Congo - Brazzaville',
        id: 'country.congo_brazzaville'
    }),
    CH: defineMessage({
        defaultMessage: 'Switzerland',
        id: 'country.switzerland'
    }),
    CI: defineMessage({
        defaultMessage: "Cote d'Ivoire",
        id: 'country.cote_d_ivoire'
    }),
    CK: defineMessage({
        defaultMessage: 'Cook Islands',
        id: 'country.cook_islands'
    }),
    CL: defineMessage({
        defaultMessage: 'Chile',
        id: 'country.chile'
    }),
    CM: defineMessage({
        defaultMessage: 'Cameroon',
        id: 'country.cameroon'
    }),
    CN: defineMessage({
        defaultMessage: 'China',
        id: 'country.china'
    }),
    CO: defineMessage({
        defaultMessage: 'Colombia',
        id: 'country.colombia'
    }),
    CP: defineMessage({
        defaultMessage: 'Clipperton Island',
        id: 'country.clipperton_island'
    }),
    CR: defineMessage({
        defaultMessage: 'Costa Rica',
        id: 'country.costa_rica'
    }),
    CU: defineMessage({
        defaultMessage: 'Cuba',
        id: 'country.cuba'
    }),
    CV: defineMessage({
        defaultMessage: 'Cape Verde',
        id: 'country.cape_verde'
    }),
    CW: defineMessage({
        defaultMessage: 'Curacao',
        id: 'country.curacao'
    }),
    CX: defineMessage({
        defaultMessage: 'Christmas Island',
        id: 'country.christmas_island'
    }),
    CY: defineMessage({
        defaultMessage: 'Cyprus',
        id: 'country.cyprus'
    }),
    CZ: defineMessage({
        defaultMessage: 'Czech Republic',
        id: 'country.czech_republic'
    }),
    DE: defineMessage({
        defaultMessage: 'Germany',
        id: 'country.germany'
    }),
    DG: defineMessage({
        defaultMessage: 'Diego Garcia',
        id: 'country.diego_garcia'
    }),
    DJ: defineMessage({
        defaultMessage: 'Djibouti',
        id: 'country.djibouti'
    }),
    DK: defineMessage({
        defaultMessage: 'Denmark',
        id: 'country.denmark'
    }),
    DM: defineMessage({
        defaultMessage: 'Dominica',
        id: 'country.dominica'
    }),
    DO: defineMessage({
        defaultMessage: 'Dominican Republic',
        id: 'country.dominican_republic'
    }),
    DZ: defineMessage({
        defaultMessage: 'Algeria',
        id: 'country.algeria'
    }),
    EC: defineMessage({
        defaultMessage: 'Ecuador',
        id: 'country.ecuador'
    }),
    EE: defineMessage({
        defaultMessage: 'Estonia',
        id: 'country.estonia'
    }),
    EG: defineMessage({
        defaultMessage: 'Egypt',
        id: 'country.egypt'
    }),
    EH: defineMessage({
        defaultMessage: 'Western Sahara',
        id: 'country.western_sahara'
    }),
    ER: defineMessage({
        defaultMessage: 'Eritrea',
        id: 'country.eritrea'
    }),
    ES: defineMessage({
        defaultMessage: 'Spain',
        id: 'country.spain'
    }),
    ET: defineMessage({
        defaultMessage: 'Ethiopia',
        id: 'country.ethiopia'
    }),
    FI: defineMessage({
        defaultMessage: 'Finland',
        id: 'country.finland'
    }),
    FJ: defineMessage({
        defaultMessage: 'Fiji',
        id: 'country.fiji'
    }),
    FK: defineMessage({
        defaultMessage: 'Falkland Islands',
        id: 'country.falkland_islands'
    }),
    FM: defineMessage({
        defaultMessage: 'Micronesia',
        id: 'country.micronesia'
    }),
    FO: defineMessage({
        defaultMessage: 'Faroe Islands',
        id: 'country.faroe_islands'
    }),
    FR: defineMessage({
        defaultMessage: 'France',
        id: 'country.france'
    }),
    GA: defineMessage({
        defaultMessage: 'Gabon',
        id: 'country.gabon'
    }),
    GB: defineMessage({
        defaultMessage: 'United Kingdom',
        id: 'country.united_kingdom'
    }),
    GD: defineMessage({
        defaultMessage: 'Grenada',
        id: 'country.grenada'
    }),
    GE: defineMessage({
        defaultMessage: 'Georgia',
        id: 'country.georgia'
    }),
    GF: defineMessage({
        defaultMessage: 'French Guiana',
        id: 'country.french_guiana'
    }),
    GG: defineMessage({
        defaultMessage: 'Guernsey',
        id: 'country.guernsey'
    }),
    GH: defineMessage({
        defaultMessage: 'Ghana',
        id: 'country.ghana'
    }),
    GI: defineMessage({
        defaultMessage: 'Gibraltar',
        id: 'country.gibraltar'
    }),
    GL: defineMessage({
        defaultMessage: 'Greenland',
        id: 'country.greenland'
    }),
    GM: defineMessage({
        defaultMessage: 'Gambia',
        id: 'country.gambia'
    }),
    GN: defineMessage({
        defaultMessage: 'Guinea',
        id: 'country.guinea'
    }),
    GP: defineMessage({
        defaultMessage: 'Guadeloupe',
        id: 'country.guadeloupe'
    }),
    GQ: defineMessage({
        defaultMessage: 'Equatorial Guinea',
        id: 'country.equatorial_guinea'
    }),
    GR: defineMessage({
        defaultMessage: 'Greece',
        id: 'country.greece'
    }),
    GS: defineMessage({
        defaultMessage: 'South Georgia and the South Sandwich Islands',
        id: 'country.south_georgia_and_the_south_sandwich_islands'
    }),
    GT: defineMessage({
        defaultMessage: 'Guatemala',
        id: 'country.guatemala'
    }),
    GU: defineMessage({
        defaultMessage: 'Guam',
        id: 'country.guam'
    }),
    GW: defineMessage({
        defaultMessage: 'Guinea-Bissau',
        id: 'country.guinea_bissau'
    }),
    GY: defineMessage({
        defaultMessage: 'Guyana',
        id: 'country.guyana'
    }),
    HK: defineMessage({
        defaultMessage: 'Hong Kong SAR - China',
        id: 'country.hong_kong_sar_china'
    }),
    HM: defineMessage({
        defaultMessage: 'Heard Island and McDonald Islands',
        id: 'country.heard_island_and_mcdonald_islands'
    }),
    HN: defineMessage({
        defaultMessage: 'Honduras',
        id: 'country.honduras'
    }),
    HR: defineMessage({
        defaultMessage: 'Croatia',
        id: 'country.croatia'
    }),
    HT: defineMessage({
        defaultMessage: 'Haiti',
        id: 'country.haiti'
    }),
    HU: defineMessage({
        defaultMessage: 'Hungary',
        id: 'country.hungary'
    }),
    ID: defineMessage({
        defaultMessage: 'Indonesia',
        id: 'country.indonesia'
    }),
    IE: defineMessage({
        defaultMessage: 'Ireland',
        id: 'country.ireland'
    }),
    IL: defineMessage({
        defaultMessage: 'Israel',
        id: 'country.israel'
    }),
    IM: defineMessage({
        defaultMessage: 'Isle of Man',
        id: 'country.isle_of_man'
    }),
    IN: defineMessage({
        defaultMessage: 'India',
        id: 'country.india'
    }),
    IO: defineMessage({
        defaultMessage: 'British Indian Ocean Territory',
        id: 'country.british_indian_ocean_territory'
    }),
    IQ: defineMessage({
        defaultMessage: 'Iraq',
        id: 'country.iraq'
    }),
    IR: defineMessage({
        defaultMessage: 'Iran',
        id: 'country.iran'
    }),
    IS: defineMessage({
        defaultMessage: 'Iceland',
        id: 'country.iceland'
    }),
    IT: defineMessage({
        defaultMessage: 'Italy',
        id: 'country.italy'
    }),
    JE: defineMessage({
        defaultMessage: 'Jersey',
        id: 'country.jersey'
    }),
    JM: defineMessage({
        defaultMessage: 'Jamaica',
        id: 'country.jamaica'
    }),
    JO: defineMessage({
        defaultMessage: 'Jordan',
        id: 'country.jordan'
    }),
    JP: defineMessage({
        defaultMessage: 'Japan',
        id: 'country.japan'
    }),
    KE: defineMessage({
        defaultMessage: 'Kenya',
        id: 'country.kenya'
    }),
    KG: defineMessage({
        defaultMessage: 'Kyrgyzstan',
        id: 'country.kyrgyzstan'
    }),
    KH: defineMessage({
        defaultMessage: 'Cambodia',
        id: 'country.cambodia'
    }),
    KI: defineMessage({
        defaultMessage: 'Kiribati',
        id: 'country.kiribati'
    }),
    KM: defineMessage({
        defaultMessage: 'Comoros',
        id: 'country.comoros'
    }),
    KN: defineMessage({
        defaultMessage: 'Saint Kitts and Nevis',
        id: 'country.saint_kitts_and_nevis'
    }),
    KP: defineMessage({
        defaultMessage: 'North Korea',
        id: 'country.north_korea'
    }),
    KR: defineMessage({
        defaultMessage: 'South Korea',
        id: 'country.south_korea'
    }),
    KW: defineMessage({
        defaultMessage: 'Kuwait',
        id: 'country.kuwait'
    }),
    KY: defineMessage({
        defaultMessage: 'Cayman Islands',
        id: 'country.cayman_islands'
    }),
    KZ: defineMessage({
        defaultMessage: 'Kazakhstan',
        id: 'country.kazakhstan'
    }),
    LA: defineMessage({
        defaultMessage: 'Laos',
        id: 'country.laos'
    }),
    LB: defineMessage({
        defaultMessage: 'Lebanon',
        id: 'country.lebanon'
    }),
    LC: defineMessage({
        defaultMessage: 'Saint Lucia',
        id: 'country.saint_lucia'
    }),
    LI: defineMessage({
        defaultMessage: 'Liechtenstein',
        id: 'country.liechtenstein'
    }),
    LK: defineMessage({
        defaultMessage: 'Sri Lanka',
        id: 'country.sri_lanka'
    }),
    LR: defineMessage({
        defaultMessage: 'Liberia',
        id: 'country.liberia'
    }),
    LS: defineMessage({
        defaultMessage: 'Lesotho',
        id: 'country.lesotho'
    }),
    LT: defineMessage({
        defaultMessage: 'Lithuania',
        id: 'country.lithuania'
    }),
    LU: defineMessage({
        defaultMessage: 'Luxembourg',
        id: 'country.luxembourg'
    }),
    LV: defineMessage({
        defaultMessage: 'Latvia',
        id: 'country.latvia'
    }),
    LY: defineMessage({
        defaultMessage: 'Libya',
        id: 'country.libya'
    }),
    MA: defineMessage({
        defaultMessage: 'Morocco',
        id: 'country.morocco'
    }),
    MC: defineMessage({
        defaultMessage: 'Monaco',
        id: 'country.monaco'
    }),
    MD: defineMessage({
        defaultMessage: 'Moldova',
        id: 'country.moldova'
    }),
    ME: defineMessage({
        defaultMessage: 'Montenegro',
        id: 'country.montenegro'
    }),
    MF: defineMessage({
        defaultMessage: 'Saint Martin',
        id: 'country.saint_martin'
    }),
    MG: defineMessage({
        defaultMessage: 'Madagascar',
        id: 'country.madagascar'
    }),
    MH: defineMessage({
        defaultMessage: 'Marshall Islands',
        id: 'country.marshall_islands'
    }),
    MK: defineMessage({
        defaultMessage: 'Macedonia',
        id: 'country.macedonia'
    }),
    ML: defineMessage({
        defaultMessage: 'Mali',
        id: 'country.mali'
    }),
    MM: defineMessage({
        defaultMessage: 'Myanmar [Burma]',
        id: 'country.myanmar_burma'
    }),
    MN: defineMessage({
        defaultMessage: 'Mongolia',
        id: 'country.mongolia'
    }),
    MO: defineMessage({
        defaultMessage: 'Macau SAR - China',
        id: 'country.macau_sar_china'
    }),
    MP: defineMessage({
        defaultMessage: 'Northern Mariana Islands',
        id: 'country.northern_mariana_islands'
    }),
    MQ: defineMessage({
        defaultMessage: 'Martinique',
        id: 'country.martinique'
    }),
    MR: defineMessage({
        defaultMessage: 'Mauritania',
        id: 'country.mauritania'
    }),
    MS: defineMessage({
        defaultMessage: 'Montserrat',
        id: 'country.montserrat'
    }),
    MT: defineMessage({
        defaultMessage: 'Malta',
        id: 'country.malta'
    }),
    MU: defineMessage({
        defaultMessage: 'Mauritius',
        id: 'country.mauritius'
    }),
    MV: defineMessage({
        defaultMessage: 'Maldives',
        id: 'country.maldives'
    }),
    MW: defineMessage({
        defaultMessage: 'Malawi',
        id: 'country.malawi'
    }),
    MX: defineMessage({
        defaultMessage: 'Mexico',
        id: 'country.mexico'
    }),
    MY: defineMessage({
        defaultMessage: 'Malaysia',
        id: 'country.malaysia'
    }),
    MZ: defineMessage({
        defaultMessage: 'Mozambique',
        id: 'country.mozambique'
    }),
    NA: defineMessage({
        defaultMessage: 'Namibia',
        id: 'country.namibia'
    }),
    NC: defineMessage({
        defaultMessage: 'New Caledonia',
        id: 'country.new_caledonia'
    }),
    NE: defineMessage({
        defaultMessage: 'Niger',
        id: 'country.niger'
    }),
    NF: defineMessage({
        defaultMessage: 'Norfolk Island',
        id: 'country.norfolk_island'
    }),
    NG: defineMessage({
        defaultMessage: 'Nigeria',
        id: 'country.nigeria'
    }),
    NI: defineMessage({
        defaultMessage: 'Nicaragua',
        id: 'country.nicaragua'
    }),
    NL: defineMessage({
        defaultMessage: 'Netherlands',
        id: 'country.netherlands'
    }),
    NO: defineMessage({
        defaultMessage: 'Norway',
        id: 'country.norway'
    }),
    NP: defineMessage({
        defaultMessage: 'Nepal',
        id: 'country.nepal'
    }),
    NR: defineMessage({
        defaultMessage: 'Nauru',
        id: 'country.nauru'
    }),
    NU: defineMessage({
        defaultMessage: 'Niue',
        id: 'country.niue'
    }),
    NZ: defineMessage({
        defaultMessage: 'New Zealand',
        id: 'country.new_zealand'
    }),
    OM: defineMessage({
        defaultMessage: 'Oman',
        id: 'country.oman'
    }),
    PA: defineMessage({
        defaultMessage: 'Panama',
        id: 'country.panama'
    }),
    PE: defineMessage({
        defaultMessage: 'Peru',
        id: 'country.peru'
    }),
    PF: defineMessage({
        defaultMessage: 'French Polynesia',
        id: 'country.french_polynesia'
    }),
    PG: defineMessage({
        defaultMessage: 'Papua New Guinea',
        id: 'country.papua_new_guinea'
    }),
    PH: defineMessage({
        defaultMessage: 'Philippines',
        id: 'country.philippines'
    }),
    PK: defineMessage({
        defaultMessage: 'Pakistan',
        id: 'country.pakistan'
    }),
    PL: defineMessage({
        defaultMessage: 'Poland',
        id: 'country.poland'
    }),
    PM: defineMessage({
        defaultMessage: 'Saint Pierre and Miquelon',
        id: 'country.saint_pierre_and_miquelon'
    }),
    PN: defineMessage({
        defaultMessage: 'Pitcairn Islands',
        id: 'country.pitcairn_islands'
    }),
    PR: defineMessage({
        defaultMessage: 'Puerto Rico',
        id: 'country.puerto_rico'
    }),
    PS: defineMessage({
        defaultMessage: 'Palestinian Territories',
        id: 'country.palestinian_territories'
    }),
    PT: defineMessage({
        defaultMessage: 'Portugal',
        id: 'country.portugal'
    }),
    PW: defineMessage({
        defaultMessage: 'Palau',
        id: 'country.palau'
    }),
    PY: defineMessage({
        defaultMessage: 'Paraguay',
        id: 'country.paraguay'
    }),
    QA: defineMessage({
        defaultMessage: 'Qatar',
        id: 'country.qatar'
    }),
    RE: defineMessage({
        defaultMessage: 'Reunion',
        id: 'country.reunion'
    }),
    RO: defineMessage({
        defaultMessage: 'Romania',
        id: 'country.romania'
    }),
    RS: defineMessage({
        defaultMessage: 'Serbia',
        id: 'country.serbia'
    }),
    RU: defineMessage({
        defaultMessage: 'Russia',
        id: 'country.russia'
    }),
    RW: defineMessage({
        defaultMessage: 'Rwanda',
        id: 'country.rwanda'
    }),
    SA: defineMessage({
        defaultMessage: 'Saudi Arabia',
        id: 'country.saudi_arabia'
    }),
    SB: defineMessage({
        defaultMessage: 'Solomon Islands',
        id: 'country.solomon_islands'
    }),
    SC: defineMessage({
        defaultMessage: 'Seychelles',
        id: 'country.seychelles'
    }),
    SD: defineMessage({
        defaultMessage: 'Sudan',
        id: 'country.sudan'
    }),
    SE: defineMessage({
        defaultMessage: 'Sweden',
        id: 'country.sweden'
    }),
    SG: defineMessage({
        defaultMessage: 'Singapore',
        id: 'country.singapore'
    }),
    SH: defineMessage({
        defaultMessage: 'Saint Helena',
        id: 'country.saint_helena'
    }),
    SI: defineMessage({
        defaultMessage: 'Slovenia',
        id: 'country.slovenia'
    }),
    SJ: defineMessage({
        defaultMessage: 'Svalbard and Jan Mayen',
        id: 'country.svalbard_and_jan_mayen'
    }),
    SK: defineMessage({
        defaultMessage: 'Slovakia',
        id: 'country.slovakia'
    }),
    SL: defineMessage({
        defaultMessage: 'Sierra Leone',
        id: 'country.sierra_leone'
    }),
    SM: defineMessage({
        defaultMessage: 'San Marino',
        id: 'country.san_marino'
    }),
    SN: defineMessage({
        defaultMessage: 'Senegal',
        id: 'country.senegal'
    }),
    SO: defineMessage({
        defaultMessage: 'Somalia',
        id: 'country.somalia'
    }),
    SR: defineMessage({
        defaultMessage: 'Suriname',
        id: 'country.suriname'
    }),
    SS: defineMessage({
        defaultMessage: 'South Sudan',
        id: 'country.south_sudan'
    }),
    ST: defineMessage({
        defaultMessage: 'Sao Tome and Principe',
        id: 'country.sao_tome_and_principe'
    }),
    SV: defineMessage({
        defaultMessage: 'El Salvador',
        id: 'country.el_salvador'
    }),
    SX: defineMessage({
        defaultMessage: 'Sint Maarten',
        id: 'country.sint_maarten'
    }),
    SY: defineMessage({
        defaultMessage: 'Syria',
        id: 'country.syria'
    }),
    SZ: defineMessage({
        defaultMessage: 'Swaziland',
        id: 'country.swaziland'
    }),
    TA: defineMessage({
        defaultMessage: 'Tristan da Cunha',
        id: 'country.tristan_da_cunha'
    }),
    TC: defineMessage({
        defaultMessage: 'Turks and Caicos Islands',
        id: 'country.turks_and_caicos_islands'
    }),
    TD: defineMessage({
        defaultMessage: 'Chad',
        id: 'country.chad'
    }),
    TF: defineMessage({
        defaultMessage: 'French Southern Territories',
        id: 'country.french_southern_territories'
    }),
    TG: defineMessage({
        defaultMessage: 'Togo',
        id: 'country.togo'
    }),
    TH: defineMessage({
        defaultMessage: 'Thailand',
        id: 'country.thailand'
    }),
    TJ: defineMessage({
        defaultMessage: 'Tajikistan',
        id: 'country.tajikistan'
    }),
    TK: defineMessage({
        defaultMessage: 'Tokelau',
        id: 'country.tokelau'
    }),
    TL: defineMessage({
        defaultMessage: 'Timor-Leste',
        id: 'country.timor_leste'
    }),
    TM: defineMessage({
        defaultMessage: 'Turkmenistan',
        id: 'country.turkmenistan'
    }),
    TN: defineMessage({
        defaultMessage: 'Tunisia',
        id: 'country.tunisia'
    }),
    TO: defineMessage({
        defaultMessage: 'Tonga',
        id: 'country.tonga'
    }),
    TR: defineMessage({
        defaultMessage: 'Turkey',
        id: 'country.turkey'
    }),
    TT: defineMessage({
        defaultMessage: 'Trinidad and Tobago',
        id: 'country.trinidad_and_tobago'
    }),
    TV: defineMessage({
        defaultMessage: 'Tuvalu',
        id: 'country.tuvalu'
    }),
    TW: defineMessage({
        defaultMessage: 'Taiwan - China',
        id: 'country.taiwan_china'
    }),
    TZ: defineMessage({
        defaultMessage: 'Tanzania',
        id: 'country.tanzania'
    }),
    UA: defineMessage({
        defaultMessage: 'Ukraine',
        id: 'country.ukraine'
    }),
    UG: defineMessage({
        defaultMessage: 'Uganda',
        id: 'country.uganda'
    }),
    UM: defineMessage({
        defaultMessage: 'U.S. Minor Outlying Islands',
        id: 'country.us_minor_outlying_islands'
    }),
    US: defineMessage({
        defaultMessage: 'United States',
        id: 'country.united_states'
    }),
    UY: defineMessage({
        defaultMessage: 'Uruguay',
        id: 'country.uruguay'
    }),
    UZ: defineMessage({
        defaultMessage: 'Uzbekistan',
        id: 'country.uzbekistan'
    }),
    VA: defineMessage({
        defaultMessage: 'Vatican City',
        id: 'country.vatican_city'
    }),
    VC: defineMessage({
        defaultMessage: 'Saint Vincent and the Grenadines',
        id: 'country.saint_vincent_and_the_grenadines'
    }),
    VE: defineMessage({
        defaultMessage: 'Venezuela',
        id: 'country.venezuela'
    }),
    VG: defineMessage({
        defaultMessage: 'British Virgin Islands',
        id: 'country.british_virgin_islands'
    }),
    VI: defineMessage({
        defaultMessage: 'U.S. Virgin Islands',
        id: 'country.us_virgin_islands'
    }),
    VN: defineMessage({
        defaultMessage: 'Vietnam',
        id: 'country.vietnam'
    }),
    VU: defineMessage({
        defaultMessage: 'Vanuatu',
        id: 'country.vanuatu'
    }),
    WF: defineMessage({
        defaultMessage: 'Wallis and Futuna',
        id: 'country.wallis_and_futuna'
    }),
    WS: defineMessage({
        defaultMessage: 'Samoa',
        id: 'country.samoa'
    }),
    XK: defineMessage({
        defaultMessage: 'Kosovo',
        id: 'country.kosovo'
    }),
    YE: defineMessage({
        defaultMessage: 'Yemen',
        id: 'country.yemen'
    }),
    YT: defineMessage({
        defaultMessage: 'Mayotte',
        id: 'country.mayotte'
    }),
    ZA: defineMessage({
        defaultMessage: 'South Africa',
        id: 'country.south_africa'
    }),
    ZM: defineMessage({
        defaultMessage: 'Zambia',
        id: 'country.zambia'
    }),
    ZW: defineMessage({
        defaultMessage: 'Zimbabwe',
        id: 'country.zimbabwe'
    })
}

// 260 countries: list to be translated by useCountryList hook
export const COUNTRY_CODES = [
    {value: 'AC', label: 'Ascension Island'},
    {value: 'AD', label: 'Andorra'},
    {value: 'AE', label: 'United Arab Emirates'},
    {value: 'AF', label: 'Afghanistan'},
    {value: 'AG', label: 'Antigua and Barbuda'},
    {value: 'AI', label: 'Anguilla'},
    {value: 'AL', label: 'Albania'},
    {value: 'AM', label: 'Armenia'},
    {value: 'AN', label: 'Netherlands Antilles'},
    {value: 'AO', label: 'Angola'},
    {value: 'AQ', label: 'Antarctica'},
    {value: 'AR', label: 'Argentina'},
    {value: 'AS', label: 'American Samoa'},
    {value: 'AT', label: 'Austria'},
    {value: 'AU', label: 'Australia'},
    {value: 'AW', label: 'Aruba'},
    {value: 'AX', label: 'Aland Islands'},
    {value: 'AZ', label: 'Azerbaijan'},
    {value: 'BA', label: 'Bosnia and Herzegovina'},
    {value: 'BB', label: 'Barbados'},
    {value: 'BD', label: 'Bangladesh'},
    {value: 'BE', label: 'Belgium'},
    {value: 'BF', label: 'Burkina Faso'},
    {value: 'BG', label: 'Bulgaria'},
    {value: 'BH', label: 'Bahrain'},
    {value: 'BI', label: 'Burundi'},
    {value: 'BJ', label: 'Benin'},
    {value: 'BL', label: 'Saint Barthelemy'},
    {value: 'BM', label: 'Bermuda'},
    {value: 'BN', label: 'Brunei'},
    {value: 'BO', label: 'Bolivia'},
    {value: 'BQ', label: 'Bonaire, Sint Eustatius, and Saba'},
    {value: 'BR', label: 'Brazil'},
    {value: 'BS', label: 'Bahamas'},
    {value: 'BT', label: 'Bhutan'},
    {value: 'BV', label: 'Bouvet Island'},
    {value: 'BW', label: 'Botswana'},
    {value: 'BY', label: 'Belarus'},
    {value: 'BZ', label: 'Belize'},
    {value: 'CA', label: 'Canada'},
    {value: 'CC', label: 'Cocos [Keeling] Islands'},
    {value: 'CD', label: 'Congo - Kinshasa'},
    {value: 'CF', label: 'Central African Republic'},
    {value: 'CG', label: 'Congo - Brazzaville'},
    {value: 'CH', label: 'Switzerland'},
    {value: 'CI', label: "Cote d'Ivoire"},
    {value: 'CK', label: 'Cook Islands'},
    {value: 'CL', label: 'Chile'},
    {value: 'CM', label: 'Cameroon'},
    {value: 'CN', label: 'China'},
    {value: 'CO', label: 'Colombia'},
    {value: 'CP', label: 'Clipperton Island'},
    {value: 'CR', label: 'Costa Rica'},
    {value: 'CU', label: 'Cuba'},
    {value: 'CV', label: 'Cape Verde'},
    {value: 'CW', label: 'Curacao'},
    {value: 'CX', label: 'Christmas Island'},
    {value: 'CY', label: 'Cyprus'},
    {value: 'CZ', label: 'Czech Republic'},
    {value: 'DE', label: 'Germany'},
    {value: 'DG', label: 'Diego Garcia'},
    {value: 'DJ', label: 'Djibouti'},
    {value: 'DK', label: 'Denmark'},
    {value: 'DM', label: 'Dominica'},
    {value: 'DO', label: 'Dominican Republic'},
    {value: 'DZ', label: 'Algeria'},
    {value: 'EC', label: 'Ecuador'},
    {value: 'EE', label: 'Estonia'},
    {value: 'EG', label: 'Egypt'},
    {value: 'EH', label: 'Western Sahara'},
    {value: 'ER', label: 'Eritrea'},
    {value: 'ES', label: 'Spain'},
    {value: 'ET', label: 'Ethiopia'},
    {value: 'FI', label: 'Finland'},
    {value: 'FJ', label: 'Fiji'},
    {value: 'FK', label: 'Falkland Islands'},
    {value: 'FM', label: 'Micronesia'},
    {value: 'FO', label: 'Faroe Islands'},
    {value: 'FR', label: 'France'},
    {value: 'GA', label: 'Gabon'},
    {value: 'GB', label: 'United Kingdom'},
    {value: 'GD', label: 'Grenada'},
    {value: 'GE', label: 'Georgia'},
    {value: 'GF', label: 'French Guiana'},
    {value: 'GG', label: 'Guernsey'},
    {value: 'GH', label: 'Ghana'},
    {value: 'GI', label: 'Gibraltar'},
    {value: 'GL', label: 'Greenland'},
    {value: 'GM', label: 'Gambia'},
    {value: 'GN', label: 'Guinea'},
    {value: 'GP', label: 'Guadeloupe'},
    {value: 'GQ', label: 'Equatorial Guinea'},
    {value: 'GR', label: 'Greece'},
    {value: 'GS', label: 'South Georgia and the South Sandwich Islands'},
    {value: 'GT', label: 'Guatemala'},
    {value: 'GU', label: 'Guam'},
    {value: 'GW', label: 'Guinea-Bissau'},
    {value: 'GY', label: 'Guyana'},
    {value: 'HK', label: 'Hong Kong SAR - China'},
    {value: 'HM', label: 'Heard Island and McDonald Islands'},
    {value: 'HN', label: 'Honduras'},
    {value: 'HR', label: 'Croatia'},
    {value: 'HT', label: 'Haiti'},
    {value: 'HU', label: 'Hungary'},
    {value: 'ID', label: 'Indonesia'},
    {value: 'IE', label: 'Ireland'},
    {value: 'IL', label: 'Israel'},
    {value: 'IM', label: 'Isle of Man'},
    {value: 'IN', label: 'India'},
    {value: 'IO', label: 'British Indian Ocean Territory'},
    {value: 'IQ', label: 'Iraq'},
    {value: 'IR', label: 'Iran'},
    {value: 'IS', label: 'Iceland'},
    {value: 'IT', label: 'Italy'},
    {value: 'JE', label: 'Jersey'},
    {value: 'JM', label: 'Jamaica'},
    {value: 'JO', label: 'Jordan'},
    {value: 'JP', label: 'Japan'},
    {value: 'KE', label: 'Kenya'},
    {value: 'KG', label: 'Kyrgyzstan'},
    {value: 'KH', label: 'Cambodia'},
    {value: 'KI', label: 'Kiribati'},
    {value: 'KM', label: 'Comoros'},
    {value: 'KN', label: 'Saint Kitts and Nevis'},
    {value: 'KP', label: 'North Korea'},
    {value: 'KR', label: 'South Korea'},
    {value: 'KW', label: 'Kuwait'},
    {value: 'KY', label: 'Cayman Islands'},
    {value: 'KZ', label: 'Kazakhstan'},
    {value: 'LA', label: 'Laos'},
    {value: 'LB', label: 'Lebanon'},
    {value: 'LC', label: 'Saint Lucia'},
    {value: 'LI', label: 'Liechtenstein'},
    {value: 'LK', label: 'Sri Lanka'},
    {value: 'LR', label: 'Liberia'},
    {value: 'LS', label: 'Lesotho'},
    {value: 'LT', label: 'Lithuania'},
    {value: 'LU', label: 'Luxembourg'},
    {value: 'LV', label: 'Latvia'},
    {value: 'LY', label: 'Libya'},
    {value: 'MA', label: 'Morocco'},
    {value: 'MC', label: 'Monaco'},
    {value: 'MD', label: 'Moldova'},
    {value: 'ME', label: 'Montenegro'},
    {value: 'MF', label: 'Saint Martin'},
    {value: 'MG', label: 'Madagascar'},
    {value: 'MH', label: 'Marshall Islands'},
    {value: 'MK', label: 'Macedonia'},
    {value: 'ML', label: 'Mali'},
    {value: 'MM', label: 'Myanmar [Burma]'},
    {value: 'MN', label: 'Mongolia'},
    {value: 'MO', label: 'Macau SAR - China'},
    {value: 'MP', label: 'Northern Mariana Islands'},
    {value: 'MQ', label: 'Martinique'},
    {value: 'MR', label: 'Mauritania'},
    {value: 'MS', label: 'Montserrat'},
    {value: 'MT', label: 'Malta'},
    {value: 'MU', label: 'Mauritius'},
    {value: 'MV', label: 'Maldives'},
    {value: 'MW', label: 'Malawi'},
    {value: 'MX', label: 'Mexico'},
    {value: 'MY', label: 'Malaysia'},
    {value: 'MZ', label: 'Mozambique'},
    {value: 'NA', label: 'Namibia'},
    {value: 'NC', label: 'New Caledonia'},
    {value: 'NE', label: 'Niger'},
    {value: 'NF', label: 'Norfolk Island'},
    {value: 'NG', label: 'Nigeria'},
    {value: 'NI', label: 'Nicaragua'},
    {value: 'NL', label: 'Netherlands'},
    {value: 'NO', label: 'Norway'},
    {value: 'NP', label: 'Nepal'},
    {value: 'NR', label: 'Nauru'},
    {value: 'NU', label: 'Niue'},
    {value: 'NZ', label: 'New Zealand'},
    {value: 'OM', label: 'Oman'},
    {value: 'PA', label: 'Panama'},
    {value: 'PE', label: 'Peru'},
    {value: 'PF', label: 'French Polynesia'},
    {value: 'PG', label: 'Papua New Guinea'},
    {value: 'PH', label: 'Philippines'},
    {value: 'PK', label: 'Pakistan'},
    {value: 'PL', label: 'Poland'},
    {value: 'PM', label: 'Saint Pierre and Miquelon'},
    {value: 'PN', label: 'Pitcairn Islands'},
    {value: 'PR', label: 'Puerto Rico'},
    {value: 'PS', label: 'Palestinian Territories'},
    {value: 'PT', label: 'Portugal'},
    {value: 'PW', label: 'Palau'},
    {value: 'PY', label: 'Paraguay'},
    {value: 'QA', label: 'Qatar'},
    {value: 'RE', label: 'Reunion'},
    {value: 'RO', label: 'Romania'},
    {value: 'RS', label: 'Serbia'},
    {value: 'RU', label: 'Russia'},
    {value: 'RW', label: 'Rwanda'},
    {value: 'SA', label: 'Saudi Arabia'},
    {value: 'SB', label: 'Solomon Islands'},
    {value: 'SC', label: 'Seychelles'},
    {value: 'SD', label: 'Sudan'},
    {value: 'SE', label: 'Sweden'},
    {value: 'SG', label: 'Singapore'},
    {value: 'SH', label: 'Saint Helena'},
    {value: 'SI', label: 'Slovenia'},
    {value: 'SJ', label: 'Svalbard and Jan Mayen'},
    {value: 'SK', label: 'Slovakia'},
    {value: 'SL', label: 'Sierra Leone'},
    {value: 'SM', label: 'San Marino'},
    {value: 'SN', label: 'Senegal'},
    {value: 'SO', label: 'Somalia'},
    {value: 'SR', label: 'Suriname'},
    {value: 'SS', label: 'South Sudan'},
    {value: 'ST', label: 'Sao Tome and Principe'},
    {value: 'SV', label: 'El Salvador'},
    {value: 'SX', label: 'Sint Maarten'},
    {value: 'SY', label: 'Syria'},
    {value: 'SZ', label: 'Swaziland'},
    {value: 'TA', label: 'Tristan da Cunha'},
    {value: 'TC', label: 'Turks and Caicos Islands'},
    {value: 'TD', label: 'Chad'},
    {value: 'TF', label: 'French Southern Territories'},
    {value: 'TG', label: 'Togo'},
    {value: 'TH', label: 'Thailand'},
    {value: 'TJ', label: 'Tajikistan'},
    {value: 'TK', label: 'Tokelau'},
    {value: 'TL', label: 'Timor-Leste'},
    {value: 'TM', label: 'Turkmenistan'},
    {value: 'TN', label: 'Tunisia'},
    {value: 'TO', label: 'Tonga'},
    {value: 'TR', label: 'Turkey'},
    {value: 'TT', label: 'Trinidad and Tobago'},
    {value: 'TV', label: 'Tuvalu'},
    {value: 'TW', label: 'Taiwan - China'},
    {value: 'TZ', label: 'Tanzania'},
    {value: 'UA', label: 'Ukraine'},
    {value: 'UG', label: 'Uganda'},
    {value: 'UM', label: 'U.S. Minor Outlying Islands'},
    {value: 'US', label: 'United States'},
    {value: 'UY', label: 'Uruguay'},
    {value: 'UZ', label: 'Uzbekistan'},
    {value: 'VA', label: 'Vatican City'},
    {value: 'VC', label: 'Saint Vincent and the Grenadines'},
    {value: 'VE', label: 'Venezuela'},
    {value: 'VG', label: 'British Virgin Islands'},
    {value: 'VI', label: 'U.S. Virgin Islands'},
    {value: 'VN', label: 'Vietnam'},
    {value: 'VU', label: 'Vanuatu'},
    {value: 'WF', label: 'Wallis and Futuna'},
    {value: 'WS', label: 'Samoa'},
    {value: 'XK', label: 'Kosovo'},
    {value: 'YE', label: 'Yemen'},
    {value: 'YT', label: 'Mayotte'},
    {value: 'ZA', label: 'South Africa'},
    {value: 'ZM', label: 'Zambia'},
    {value: 'ZW', label: 'Zimbabwe'}
]

// dial codes from https://intl-tel-input.com/
export const INTERNATIONAL_DIAL_CODES = [
    {countryCode: 'AC', dialCode: '+247'},
    {countryCode: 'AD', dialCode: '+376'},
    {countryCode: 'AE', dialCode: '+971'},
    {countryCode: 'AF', dialCode: '+93'},
    {countryCode: 'AG', dialCode: '+1268'},
    {countryCode: 'AI', dialCode: '+1264'},
    {countryCode: 'AL', dialCode: '+355'},
    {countryCode: 'AM', dialCode: '+374'},
    {countryCode: 'AN', dialCode: '+599'},
    {countryCode: 'AO', dialCode: '+244'},
    {countryCode: 'AQ', dialCode: '+672'},
    {countryCode: 'AR', dialCode: '+54'},
    {countryCode: 'AS', dialCode: '+1684'},
    {countryCode: 'AT', dialCode: '+43'},
    {countryCode: 'AU', dialCode: '+61'},
    {countryCode: 'AW', dialCode: '+297'},
    {countryCode: 'AX', dialCode: '+358'},
    {countryCode: 'AZ', dialCode: '+994'},
    {countryCode: 'BA', dialCode: '+387'},
    {countryCode: 'BB', dialCode: '+1246'},
    {countryCode: 'BD', dialCode: '+880'},
    {countryCode: 'BE', dialCode: '+32'},
    {countryCode: 'BF', dialCode: '+226'},
    {countryCode: 'BG', dialCode: '+359'},
    {countryCode: 'BH', dialCode: '+973'},
    {countryCode: 'BI', dialCode: '+257'},
    {countryCode: 'BJ', dialCode: '+229'},
    {countryCode: 'BL', dialCode: '+590'},
    {countryCode: 'BM', dialCode: '+1441'},
    {countryCode: 'BN', dialCode: '+673'},
    {countryCode: 'BO', dialCode: '+591'},
    {countryCode: 'BQ', dialCode: '+599'},
    {countryCode: 'BR', dialCode: '+55'},
    {countryCode: 'BS', dialCode: '+1242'},
    {countryCode: 'BT', dialCode: '+975'},
    {countryCode: 'BV', dialCode: '+47'},
    {countryCode: 'BW', dialCode: '+267'},
    {countryCode: 'BY', dialCode: '+375'},
    {countryCode: 'BZ', dialCode: '+501'},
    {countryCode: 'CA', dialCode: '+1'},
    {countryCode: 'CC', dialCode: '+61'},
    {countryCode: 'CD', dialCode: '+243'},
    {countryCode: 'CF', dialCode: '+236'},
    {countryCode: 'CG', dialCode: '+242'},
    {countryCode: 'CH', dialCode: '+41'},
    {countryCode: 'CI', dialCode: '+225'},
    {countryCode: 'CK', dialCode: '+682'},
    {countryCode: 'CL', dialCode: '+56'},
    {countryCode: 'CM', dialCode: '+237'},
    {countryCode: 'CN', dialCode: '+86'},
    {countryCode: 'CO', dialCode: '+57'},
    {countryCode: 'CP', dialCode: '+689'},
    {countryCode: 'CR', dialCode: '+506'},
    {countryCode: 'CU', dialCode: '+53'},
    {countryCode: 'CV', dialCode: '+238'},
    {countryCode: 'CW', dialCode: '+599'},
    {countryCode: 'CX', dialCode: '+61'},
    {countryCode: 'CY', dialCode: '+357'},
    {countryCode: 'CZ', dialCode: '+420'},
    {countryCode: 'DE', dialCode: '+49'},
    {countryCode: 'DG', dialCode: '+246'},
    {countryCode: 'DJ', dialCode: '+253'},
    {countryCode: 'DK', dialCode: '+45'},
    {countryCode: 'DM', dialCode: '+1767'},
    {countryCode: 'DO', dialCode: '+1'},
    {countryCode: 'DZ', dialCode: '+213'},
    {countryCode: 'EC', dialCode: '+593'},
    {countryCode: 'EE', dialCode: '+372'},
    {countryCode: 'EG', dialCode: '+20'},
    {countryCode: 'EH', dialCode: '+212'},
    {countryCode: 'ER', dialCode: '+291'},
    {countryCode: 'ES', dialCode: '+34'},
    {countryCode: 'ET', dialCode: '+251'},
    {countryCode: 'FI', dialCode: '+358'},
    {countryCode: 'FJ', dialCode: '+679'},
    {countryCode: 'FK', dialCode: '+500'},
    {countryCode: 'FM', dialCode: '+691'},
    {countryCode: 'FO', dialCode: '+298'},
    {countryCode: 'FR', dialCode: '+33'},
    {countryCode: 'GA', dialCode: '+241'},
    {countryCode: 'GB', dialCode: '+44'},
    {countryCode: 'GD', dialCode: '+1473'},
    {countryCode: 'GE', dialCode: '+995'},
    {countryCode: 'GF', dialCode: '+594'},
    {countryCode: 'GG', dialCode: '+44'},
    {countryCode: 'GH', dialCode: '+233'},
    {countryCode: 'GI', dialCode: '+350'},
    {countryCode: 'GL', dialCode: '+299'},
    {countryCode: 'GM', dialCode: '+220'},
    {countryCode: 'GN', dialCode: '+224'},
    {countryCode: 'GP', dialCode: '+590'},
    {countryCode: 'GQ', dialCode: '+240'},
    {countryCode: 'GR', dialCode: '+30'},
    {countryCode: 'GS', dialCode: '+500'},
    {countryCode: 'GT', dialCode: '+502'},
    {countryCode: 'GU', dialCode: '+1671'},
    {countryCode: 'GW', dialCode: '+245'},
    {countryCode: 'GY', dialCode: '+592'},
    {countryCode: 'HK', dialCode: '+852'},
    {countryCode: 'HM', dialCode: '+672'},
    {countryCode: 'HN', dialCode: '+504'},
    {countryCode: 'HR', dialCode: '+385'},
    {countryCode: 'HT', dialCode: '+509'},
    {countryCode: 'HU', dialCode: '+36'},
    {countryCode: 'ID', dialCode: '+62'},
    {countryCode: 'IE', dialCode: '+353'},
    {countryCode: 'IL', dialCode: '+972'},
    {countryCode: 'IM', dialCode: '+44'},
    {countryCode: 'IN', dialCode: '+91'},
    {countryCode: 'IO', dialCode: '+246'},
    {countryCode: 'IQ', dialCode: '+964'},
    {countryCode: 'IR', dialCode: '+98'},
    {countryCode: 'IS', dialCode: '+354'},
    {countryCode: 'IT', dialCode: '+39'},
    {countryCode: 'JE', dialCode: '+44'},
    {countryCode: 'JM', dialCode: '+1876'},
    {countryCode: 'JO', dialCode: '+962'},
    {countryCode: 'JP', dialCode: '+81'},
    {countryCode: 'KE', dialCode: '+254'},
    {countryCode: 'KG', dialCode: '+996'},
    {countryCode: 'KH', dialCode: '+855'},
    {countryCode: 'KI', dialCode: '+686'},
    {countryCode: 'KM', dialCode: '+269'},
    {countryCode: 'KN', dialCode: '+1869'},
    {countryCode: 'KP', dialCode: '+850'},
    {countryCode: 'KR', dialCode: '+82'},
    {countryCode: 'KW', dialCode: '+965'},
    {countryCode: 'KY', dialCode: '+1345'},
    {countryCode: 'KZ', dialCode: '+7'},
    {countryCode: 'LA', dialCode: '+856'},
    {countryCode: 'LB', dialCode: '+961'},
    {countryCode: 'LC', dialCode: '+1758'},
    {countryCode: 'LI', dialCode: '+423'},
    {countryCode: 'LK', dialCode: '+94'},
    {countryCode: 'LR', dialCode: '+231'},
    {countryCode: 'LS', dialCode: '+266'},
    {countryCode: 'LT', dialCode: '+370'},
    {countryCode: 'LU', dialCode: '+352'},
    {countryCode: 'LV', dialCode: '+371'},
    {countryCode: 'LY', dialCode: '+218'},
    {countryCode: 'MA', dialCode: '+212'},
    {countryCode: 'MC', dialCode: '+377'},
    {countryCode: 'MD', dialCode: '+373'},
    {countryCode: 'ME', dialCode: '+382'},
    {countryCode: 'MF', dialCode: '+590'},
    {countryCode: 'MG', dialCode: '+261'},
    {countryCode: 'MH', dialCode: '+692'},
    {countryCode: 'MK', dialCode: '+389'},
    {countryCode: 'ML', dialCode: '+223'},
    {countryCode: 'MM', dialCode: '+95'},
    {countryCode: 'MN', dialCode: '+976'},
    {countryCode: 'MO', dialCode: '+853'},
    {countryCode: 'MP', dialCode: '+1670'},
    {countryCode: 'MQ', dialCode: '+596'},
    {countryCode: 'MR', dialCode: '+222'},
    {countryCode: 'MS', dialCode: '+1664'},
    {countryCode: 'MT', dialCode: '+356'},
    {countryCode: 'MU', dialCode: '+230'},
    {countryCode: 'MV', dialCode: '+960'},
    {countryCode: 'MW', dialCode: '+265'},
    {countryCode: 'MX', dialCode: '+52'},
    {countryCode: 'MY', dialCode: '+60'},
    {countryCode: 'MZ', dialCode: '+258'},
    {countryCode: 'NA', dialCode: '+264'},
    {countryCode: 'NC', dialCode: '+687'},
    {countryCode: 'NE', dialCode: '+227'},
    {countryCode: 'NF', dialCode: '+672'},
    {countryCode: 'NG', dialCode: '+234'},
    {countryCode: 'NI', dialCode: '+505'},
    {countryCode: 'NL', dialCode: '+31'},
    {countryCode: 'NO', dialCode: '+47'},
    {countryCode: 'NP', dialCode: '+977'},
    {countryCode: 'NR', dialCode: '+674'},
    {countryCode: 'NU', dialCode: '+683'},
    {countryCode: 'NZ', dialCode: '+64'},
    {countryCode: 'OM', dialCode: '+968'},
    {countryCode: 'PA', dialCode: '+507'},
    {countryCode: 'PE', dialCode: '+51'},
    {countryCode: 'PF', dialCode: '+689'},
    {countryCode: 'PG', dialCode: '+675'},
    {countryCode: 'PH', dialCode: '+63'},
    {countryCode: 'PK', dialCode: '+92'},
    {countryCode: 'PL', dialCode: '+48'},
    {countryCode: 'PM', dialCode: '+508'},
    {countryCode: 'PN', dialCode: '+64'},
    {countryCode: 'PR', dialCode: '+1'},
    {countryCode: 'PS', dialCode: '+970'},
    {countryCode: 'PT', dialCode: '+351'},
    {countryCode: 'PW', dialCode: '+680'},
    {countryCode: 'PY', dialCode: '+595'},
    {countryCode: 'QA', dialCode: '+974'},
    {countryCode: 'RE', dialCode: '+262'},
    {countryCode: 'RO', dialCode: '+40'},
    {countryCode: 'RS', dialCode: '+381'},
    {countryCode: 'RU', dialCode: '+7'},
    {countryCode: 'RW', dialCode: '+250'},
    {countryCode: 'SA', dialCode: '+966'},
    {countryCode: 'SB', dialCode: '+677'},
    {countryCode: 'SC', dialCode: '+248'},
    {countryCode: 'SD', dialCode: '+249'},
    {countryCode: 'SE', dialCode: '+46'},
    {countryCode: 'SG', dialCode: '+65'},
    {countryCode: 'SH', dialCode: '+290'},
    {countryCode: 'SI', dialCode: '+386'},
    {countryCode: 'SJ', dialCode: '+47'},
    {countryCode: 'SK', dialCode: '+421'},
    {countryCode: 'SL', dialCode: '+232'},
    {countryCode: 'SM', dialCode: '+378'},
    {countryCode: 'SN', dialCode: '+221'},
    {countryCode: 'SO', dialCode: '+252'},
    {countryCode: 'SR', dialCode: '+597'},
    {countryCode: 'SS', dialCode: '+211'},
    {countryCode: 'ST', dialCode: '+239'},
    {countryCode: 'SV', dialCode: '+503'},
    {countryCode: 'SX', dialCode: '+1721'},
    {countryCode: 'SY', dialCode: '+963'},
    {countryCode: 'SZ', dialCode: '+268'},
    {countryCode: 'TA', dialCode: '+490'},
    {countryCode: 'TC', dialCode: '+1649'},
    {countryCode: 'TF', dialCode: '+262'},
    {countryCode: 'TD', dialCode: '+235'},
    {countryCode: 'TG', dialCode: '+228'},
    {countryCode: 'TH', dialCode: '+66'},
    {countryCode: 'TJ', dialCode: '+992'},
    {countryCode: 'TK', dialCode: '+690'},
    {countryCode: 'TL', dialCode: '+670'},
    {countryCode: 'TM', dialCode: '+993'},
    {countryCode: 'TN', dialCode: '+216'},
    {countryCode: 'TO', dialCode: '+676'},
    {countryCode: 'TR', dialCode: '+90'},
    {countryCode: 'TT', dialCode: '+1868'},
    {countryCode: 'TV', dialCode: '+688'},
    {countryCode: 'TW', dialCode: '+886'},
    {countryCode: 'TZ', dialCode: '+255'},
    {countryCode: 'UA', dialCode: '+380'},
    {countryCode: 'UG', dialCode: '+256'},
    {countryCode: 'UM', dialCode: '+246'},
    {countryCode: 'US', dialCode: '+1'},
    {countryCode: 'UY', dialCode: '+598'},
    {countryCode: 'UZ', dialCode: '+998'},
    {countryCode: 'VA', dialCode: '+39'},
    {countryCode: 'VC', dialCode: '+1784'},
    {countryCode: 'VE', dialCode: '+58'},
    {countryCode: 'VG', dialCode: '+1284'},
    {countryCode: 'VI', dialCode: '+1'},
    {countryCode: 'VN', dialCode: '+84'},
    {countryCode: 'VU', dialCode: '+678'},
    {countryCode: 'WF', dialCode: '+681'},
    {countryCode: 'WS', dialCode: '+685'},
    {countryCode: 'XK', dialCode: '+383'},
    {countryCode: 'YE', dialCode: '+967'},
    {countryCode: 'YT', dialCode: '+262'},
    {countryCode: 'ZA', dialCode: '+27'},
    {countryCode: 'ZM', dialCode: '+260'},
    {countryCode: 'ZW', dialCode: '+263'}
]
