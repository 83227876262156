import React from 'react'
import PropTypes from 'prop-types'

import {Stack} from '@salesforce/retail-react-app/app/components/shared/ui'
import colors from '../../../theme/foundations/colors'
import {Component} from '@salesforce/commerce-sdk-react/components'

export const PDCFooterPayments = ({regions}) => {
    let components = []
    if (regions) {
        components = regions[0].components || []
    }

    return (
        <Stack
            direction={'row'}
            gap={{base: '32px', lg: '24px'}}
            flexWrap={'wrap'}
            paddingBottom={{base: '40px', lg: 0}}
            marginBottom={{base: '40px', lg: 0}}
            borderBottom={{base: `1px solid ${colors.borderGray}`, lg: 'none'}}
        >
            {components.map((paymentIcon, index) => (
                <Component key={index} component={paymentIcon} />
            ))}
        </Stack>
    )
}

export default PDCFooterPayments

PDCFooterPayments.propTypes = {
    regions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            components: PropTypes.arrayOf(
                PropTypes.shape({
                    data: PropTypes.object,
                    id: PropTypes.string,
                    typeId: PropTypes.string
                })
            )
        })
    )
}
