export default {
    baseStyle: {
        button: {
            fontSize: 'md',
            fontFamily: 'body',
            fontWeight: 500,
            lineHeight: 'short',
            py: '12px',
            px: 0,
            h: 'auto',
            _hover: {
                background: 'none'
            }
        },
        container: {
            borderColor: 'borderGray'
        },
        panel: {
            fontSize: 'md',
            pb: '12px',
            px: 0
        },
        icon: {
            w: '10px',
            h: '10px'
        }
    }
}
