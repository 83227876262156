/**
 * Validates if the given birth date meets the legal age requirement.
 *
 * @param {string} value - The birth date to validate in ISO format (YYYY-MM-DD).
 * @param {number} [ageLimit=16] - The minimum legal age to validate against. Defaults to 16.
 * @returns {boolean} - Returns true if the age is greater than or equal to the age limit, otherwise false.
 */
const validateAge = (value, ageLimit = 16) => {
    const today = new Date()
    const birthDate = new Date(value)
    const age = today.getFullYear() - birthDate.getFullYear()
    const monthDifference = today.getMonth() - birthDate.getMonth()

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        return age - 1 >= ageLimit
    }
    return age >= ageLimit
}

/**
 * Checks if the given date is in the past.
 *
 * @param {string} dateString - The date to check in ISO format (YYYY-MM-DD).
 * @returns {boolean} - Returns true if the date is in the past, otherwise false.
 */
const isPastDate = (value) => {
    const today = new Date()
    const birthDate = new Date(value)
    return birthDate < new Date(today.getFullYear(), today.getMonth(), today.getDate())
}

/**
 * Checks if the given birth date meets the legal age requirement and is a date in the past.
 *
 * @param {string} value - The birth date to validate in ISO format (YYYY-MM-DD).
 * @returns {Object} - An object containing two boolean properties:
 *                     - `isPastDate`: true if the birth date is in the past.
 *                     - `hasLegalAge`: true if the age is greater than or equal to the age limit.
 */
export const validateBirthday = (value) => {
    return {
        isPastDate: !value ? true : isPastDate(value),
        hasLegalAge: !value ? true : validateAge(value)
    }
}
