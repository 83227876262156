import React, {useId} from 'react'
import {Link as RouteLink} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
    Box,
    Flex,
    Link,
    LinkBox,
    LinkOverlay,
    Text,
    useStyleConfig,
    useBreakpointValue
} from '@chakra-ui/react'
import {PRODUCT_IMAGE_TYPE, DYNAMIC_IMAGE_WIDTHS} from '../../../constants'
import PDCTitle from '../../../components/pdc-title'
import DynamicImage from '@salesforce/retail-react-app/app/components/dynamic-image'
import DisplayPrice from '../../../components/display-price'
import {useProduct} from '@salesforce/commerce-sdk-react'
import {filterImageGroups, getPriceData} from '@salesforce/retail-react-app/app/utils/product-utils'
import {productUrlBuilder} from '@salesforce/retail-react-app/app/utils/url'
import {useCurrency} from '@salesforce/retail-react-app/app/hooks'
import usePDCResponsiveElement from '../../../hooks/use-pdc-responsive-element.js'
import {useUrlFromLink} from '../../helpers/urlHelper'

/**
 * This component is used to display a product with a dyptich image.
 * @param {string} product - The product id.
 * @param {string} subTitle - The subtitle of the product.
 * @param {string} desc - The description of the product.
 * @param {string} linkLabel - The label of the link.
 * @param {object} smallImage - The small image.
 * @param {object} mediumImage - The medium image.
 * @param {object} largeImage - The large image.
 * @param {object} xlargeImage - The xlarge image.
 * @param {object} xxlargeImage - The xxlarge image.
 * @param {string} imageTitle - The title of the image.
 * @param {string} imageAlt - The alt of the image.
 * @param {string} imageLink - The link of the image.
 * @param {string} textColor - The color of the text.
 * @param {string} backgroundColor - The color of the background.
 * @param {string} imagePlacement - The placement of the image.
 * @returns {React.ReactElement} - The product with a dyptich image.
 */
export const PDCHomeProductDyptichImage = ({
    product,
    subTitle,
    desc,
    linkLabel,
    smallImage,
    mediumImage,
    largeImage,
    xlargeImage,
    xxlargeImage,
    imageTitle,
    imageAlt,
    imageLink,
    textColor,
    backgroundColor,
    imagePlacement,
    custom
}) => {
    // Get the product data
    const {data: productData} = useProduct({
        parameters: {
            id: product,
            allImages: false
        }
    })
    // Get all image groups of the product.
    const filteredImageGroups = filterImageGroups(productData?.imageGroups, {
        viewType: PRODUCT_IMAGE_TYPE
    })

    // Return the first image of the first group.
    const image = filteredImageGroups?.[0]?.images[0]

    // Primary URL used to wrap the component.
    const productUrl = productUrlBuilder({id: productData?.id})

    // Get the right price for the product.
    const priceData = getPriceData(productData)

    // Get the currency
    const {currency} = useCurrency()

    // Choose image based on breakpoint. If not set, we take the closest image from the previous breakpoints
    const currentImage = useBreakpointValue(
        usePDCResponsiveElement({
            smallEl: smallImage,
            mediumEl: mediumImage,
            largeEl: largeImage,
            xlargeEl: xlargeImage,
            xxlargeEl: xxlargeImage
        })
    )

    const updatedUrl = useUrlFromLink(custom?.imageLink) || imageLink
    const isLinkExternal = custom?.imageLink?.type === 'EXTERNAL'

    const titleId = useId()

    const styles = useStyleConfig('Link', {})

    const imgBlock = currentImage && (
        <Link
            as={isLinkExternal ? Link : RouteLink}
            href={updatedUrl}
            to={updatedUrl}
            width={['100%', '100%', '50%']}
            position="relative"
            _focusVisible={{boxShadow: 'outline'}}
        >
            <DynamicImage
                src={`${currentImage.url}[?sw={width}]`}
                widths={DYNAMIC_IMAGE_WIDTHS}
                imageProps={{
                    width: '100%',
                    loading: 'auto',
                    fetchpriority: 'high',
                    alt: imageAlt === 'empty' ? '' : imageAlt || productData?.name,
                    title: imageTitle === 'empty' ? '' : imageTitle || productData?.name
                }}
            />
        </Link>
    )

    const txtBlock = productData && (
        <LinkBox
            display={'flex'}
            flexDirection="column"
            justifyContent="center"
            alignItems={'center'}
            width={['100%', null, '50%']}
            pt={'20px'}
            pb={'40px'}
            px={['10px', null, '12%']}
            color={textColor || 'fullBlack'}
            height={['654px', null, 'auto']}
        >
            <Flex justifyContent={'space-evenly'} direction={'column'} height={'100%'}>
                <DynamicImage
                    data-testid="pdc-home-product-dyptich-video-image"
                    src={`${image?.link}[?sw={width}]`}
                    display="flex"
                    justifyContent={'center'}
                    widths={DYNAMIC_IMAGE_WIDTHS}
                    imageProps={{
                        width: ['40%', null, '75%'],
                        height: 'auto',
                        loading: 'auto',
                        alt: image?.alt,
                        title: image?.title
                    }}
                />

                <Flex direction="column" alignItems="center" flex={0}>
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: subTitle
                        }}
                        variant="descriptor"
                        textAlign={'center'}
                    />

                    <PDCTitle
                        width={['90%', null, '100%']}
                        title={productData?.name}
                        titleFontColor={textColor}
                        titleHeadingLevel={'h3'}
                        txtHorizontalPos={'center'}
                        textTransform="uppercase"
                        id={titleId}
                        mt={'10px'}
                        size="4xl"
                    />

                    <DisplayPrice
                        priceData={priceData}
                        currency={currency}
                        mt={'10px'}
                        textAlign={'center'}
                        display="flex"
                        currentPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                        listPriceProps={{
                            variant: 'eyeBrowSmall'
                        }}
                    />
                    <Text
                        dangerouslySetInnerHTML={{
                            __html: desc
                        }}
                        variant="bodyLarge2"
                        textAlign={'center'}
                        mt={'16px'}
                        px={[null, null, null, '40px']}
                        color={textColor || 'darkGray'}
                    />
                    <LinkOverlay as={RouteLink} to={productUrl} mt="10px" {...styles}>
                        <Text variant="link" as="span" color={textColor} textAlign={'center'}>
                            {linkLabel}
                        </Text>
                    </LinkOverlay>
                </Flex>
            </Flex>
        </LinkBox>
    )

    return (
        <Box
            as="section"
            aria-describedby={titleId}
            display="flex"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            alignItems="stretch"
            width="100%"
            height={'auto'}
            backgroundColor={backgroundColor}
            color={textColor}
        >
            {imagePlacement === 'left (top)' ? (
                <>
                    {imgBlock}
                    {txtBlock}
                </>
            ) : (
                <>
                    {txtBlock}
                    {imgBlock}
                </>
            )}
        </Box>
    )
}

PDCHomeProductDyptichImage.propTypes = {
    product: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    desc: PropTypes.string,
    linkLabel: PropTypes.string.isRequired,
    smallImage: PropTypes.object.isRequired,
    mediumImage: PropTypes.object,
    largeImage: PropTypes.object,
    xlargeImage: PropTypes.object,
    xxlargeImage: PropTypes.object,
    imageTitle: PropTypes.string,
    imageAlt: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageLink: PropTypes.string.isRequired,
    imagePlacement: PropTypes.string.isRequired,
    custom: PropTypes.shape({
        imageLink: PropTypes.shape({
            target: PropTypes.string,
            type: PropTypes.string
        })
    })
}
